import React from "react";
import "../css/SignUpInput.css";

function SignUpInputReadOnly(props) {
	return (
		<input
			className={props.className}
			name={props.name}
			placeholder={props.placeholder}
			type={props.type}
			onChange={props.onChange}
			value={props.value}
			required={props.required || true}
			readOnly
		/>
	);
}

export default SignUpInputReadOnly;
