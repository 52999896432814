import React, { useState } from "react";
import "../css/PasswordReset.css";
import { useNavigate, useParams } from "react-router-dom";
import BASE_URL from "./Constants";
import Modal from "./Modal";
import Loading from "./Loading";
import { useSelector, useDispatch } from "react-redux";
import { changeLoadingState } from "../features/loadingState";
import api from "../api";
import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function PasswordReset() {
	const navigate = useNavigate();

	const validationCode = useParams();

	const [password, setPassword] = useState("");
	const [confirmation, setConfirmation] = useState("");

	const [isEyePasswordShown, setEyePassowordShown] = useState(false);
	const [isEyeConfirmationShown, setEyeConfirmationShown] = useState(false);

	const isLoadingGlobal = useSelector((state) => state.loadingState.value);
	const dispatchLoadingState = useDispatch();

	const [isLoadingLocal, setIsLoadingLocal] = useState(isLoadingGlobal);
	setTimeout(() => {
		setIsLoadingLocal(false);
	}, 1900);

	const [isModalOpen, setIsModalOpen] = useState("");

	let handleSubmit = async (e) => {
		e.preventDefault();

		if (password === confirmation) {
			const data = {
				email: validationCode.email,
				code: validationCode.code,
				password: password,
			};

			try {
				let res = await api.post(`${BASE_URL}/auth/setPassword`, data);

				if (res.status === 200) {
					setIsModalOpen("success");
				} else {
					setIsModalOpen("error");
				}
			} catch (error) {
				console.log(error);
			}
		} else {
			setIsModalOpen("fail");
		}
	};

	function handleClick(message) {
		if (message === "success") {
			setIsModalOpen("");
			dispatchLoadingState(changeLoadingState(true));
			navigate("/");
		} else if (message === "error") {
			setIsModalOpen("");
		} else if (message === "fail") {
			setIsModalOpen("");
		}
	}

	let passwordResetComponent = (
		<div
			className='mainBorder'
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<h3 className='resetParagraph'>
				<strong>
					Eccoti!<br></br>
				</strong>

				<p className='resetParagraph_1'>Inserisci qui la tua nuova password</p>
			</h3>

			<form
				onSubmit={handleSubmit}
				style={{
					display: "flex",
					flexDirection: "column",
					alignContent: "center",
					justifyContent: "center",
					width: "100%",
				}}
			>
				{/* PASSWORD INPUT */}
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						width: "100%",
						alignContent: "center",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<OutlinedInput
						id='outlined-adornment-password'
						value={password}
						fullWidth
						style={{
							backgroundColor: "#f4e9eb",
							border: "0px !important",
							width: "40%",
							marginBottom: "40px",
						}}
						sx={{ border: "none", "& fieldset": { border: "none" } }}
						onChange={(e) => setPassword(e.target.value)}
						type={isEyePasswordShown ? "text" : "password"}
						placeholder='Inserisci la tua nuova password'
						endAdornment={
							<InputAdornment position='end'>
								<IconButton
									aria-label='toggle password visibility'
									onClick={() => setEyePassowordShown(!isEyePasswordShown)}
									edge='end'
								>
									{isEyePasswordShown ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						}
						label='Password'
					/>
				</div>
				{/* CONFIRM PASSWORD INPUT */}
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						width: "100%",
						alignContent: "center",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<OutlinedInput
						id='outlined-adornment-password'
						value={confirmation}
						placeholder='Conferma la tua nuova password'
						fullWidth
						style={{
							backgroundColor: "#f4e9eb",
							border: "0px !important",
							width: "40%",
						}}
						sx={{ border: "none", "& fieldset": { border: "none" } }}
						onChange={(e) => setConfirmation(e.target.value)}
						type={isEyeConfirmationShown ? "text" : "password"}
						endAdornment={
							<InputAdornment position='end'>
								<IconButton
									aria-label='toggle password visibility'
									onClick={() =>
										setEyeConfirmationShown(!isEyeConfirmationShown)
									}
									edge='end'
								>
									{isEyeConfirmationShown ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						}
						label='Password'
					/>
				</div>

				<div className='resetRedirecting'>
					<button
						type='submit'
						className='resetButton'
					>
						Modifica password
					</button>
					<div className='resetButtonShadow'></div>
				</div>
			</form>

			{isModalOpen === "success" ? (
				<Modal
					style={{ top: "0", right: "7px", zIndex: "1" }}
					title='Fantastico!'
					body='La nuova password è stata modificata con successo, welcome back!'
					buttonText='Torna al login'
					onClose={() => handleClick("success")}
					onClick={() => handleClick("success")}
				/>
			) : (
				""
			)}

			{isModalOpen === "error" ? (
				<Modal
					style={{ top: "0", right: "7px", zIndex: "1" }}
					title='Opss...'
					body='Il processo di reset password non è andato a buon fine. Prova a reinserire il codice e la nuova password, oppure torna indietro per ricevere un nuovo codice.'
					buttonText='Riprova'
					onClose={() => handleClick("error")}
					onClick={() => handleClick("error")}
				/>
			) : (
				""
			)}

			{isModalOpen === "fail" ? (
				<Modal
					style={{ top: "0", right: "7px", zIndex: "1" }}
					title='Attenzione!'
					body='I campi password e conferma password risultano diversi, prova a reinserire entrambi i valori correttamente.'
					buttonText='Riprova'
					onClose={() => handleClick("fail")}
					onClick={() => handleClick("fail")}
				/>
			) : (
				""
			)}
		</div>
	);

	return <div>{isLoadingLocal ? <Loading /> : passwordResetComponent}</div>;
}

export default PasswordReset;
