import React from "react";
import { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Login";
import Register from "./Register";
import RegConfirmed from "./RegConfirmed";
import RegDenied from "./RegDenied";
import SendEmailResetPsw from "./SendEmailResetPsw";
import PasswordReset from "./PasswordReset";
import Privacy from "./Privacy";
import Home from "./Home";
import Wallet from "./Wallet";
import ValidateUser from "./ValidateUser";
import IlTuoSpazio from "./IlTuoSpazio";
import WriteContinueInook from "./WriteContinueInook";
import WriteContinueDraft from "./WriteContinueDraft";
import WriteDraftChapter from "./WriteDraftChapter";
import WriteContinueChapter from "./WriteContinueChapter";
import WriteSaveDraft from "./WriteSaveDraft";
import Profile from "./Profile";
import ModProfile from "./ModProfile";
import NewBook from "./NewBook";
import Writer from "./Writer";
import Editor from "./Editor";
import Read from "./Read";
import PublishBook from "./PublishBook";
import ShareInook from "./ShareInook";
import ReadBook from "./ReadBook";
import AlertTemplate from "../AlertTemplate";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import { Navigate, useNavigate } from "react-router-dom";
import api from "../api";
const options = {
	// you can also just use 'bottom center'
	position: positions.BOTTOM_CENTER,
	timeout: 5000,
	transition: (transitions.containerStyle = {
		zIndex: 1000,
	}),

	containerStyle: {
		fontSize: "15px",
		textTransform: "none",
	},
};

const RequireAuth = ({ children }) => {
	const navigate = useNavigate();
	const token = localStorage.getItem("token");

	useEffect(() => {
		// Verifica se il token esiste
		if (!token) {
			navigate("/");
		} else {
			// Verifica la validità del token con una chiamata API
			const verifyToken = async () => {
				try {
					await api.get(`/auth/token`, {
						headers: {
							Authorization: `Bearer ${token}`,
						},
					});
					// Se il token è valido, non fare nulla, altrimenti reindirizza
				} catch (err) {
					console.error(err);
					localStorage.removeItem("token");
					navigate("/");
				}
			};
			verifyToken();
		}
	}, [token, navigate]); // Assicurati che le dipendenze siano corrette

	// Se non c'è token, mostra un placeholder o un loader
	return token ? children : <div>Loading...</div>;
};

function App() {
	return (
		<Router>
			<Routes>
				{/* ROUTES PRE-LOGIN / REGISTRAZIONE */}
				<Route
					exact
					path='/'
					element={<Login />}
				/>
				<Route
					exact
					path='/register'
					element={<Register />}
				/>
				<Route
					exact
					path='/register/privacy'
					element={<Privacy />}
				/>
				<Route
					exact
					path='/register/validate/:code'
					element={<ValidateUser />}
				/>
				<Route
					exact
					path='/confirmed'
					element={<RegConfirmed />}
				/>
				<Route
					exact
					path='/denied'
					element={<RegDenied />}
				/>
				<Route
					exact
					path='/reset'
					element={<SendEmailResetPsw />}
				/>
				<Route
					exact
					path='/register/resetpassword/:code/:email'
					element={<PasswordReset />}
				/>

				{/* ROUTES POST-LOGIN */}
				<Route
					exact
					path='/home'
					element={
						<RequireAuth>
							<Home />
						</RequireAuth>
					}
				/>
				<Route
					exact
					path='/profile'
					element={
						<RequireAuth>
							<Profile />
						</RequireAuth>
					}
				/>
				<Route
					exact
					path='/wallet'
					element={
						<RequireAuth>
							<Wallet />
						</RequireAuth>
					}
				/>
				<Route
					exact
					path='/modprofile'
					element={
						<RequireAuth>
							<ModProfile />
						</RequireAuth>
					}
				/>
				<Route
					exact
					path='/write'
					element={
						<RequireAuth>
							<IlTuoSpazio />
						</RequireAuth>
					}
				/>
				<Route
					exact
					path='/write/newInook'
					element={
						<RequireAuth>
							<NewBook />
						</RequireAuth>
					}
				/>
				<Route
					exact
					path='/write/publishInook/:id'
					element={
						<RequireAuth>
							<PublishBook />
						</RequireAuth>
					}
				/>

				<Route
					exact
					path='/write/continueInook'
					element={
						<RequireAuth>
							<WriteContinueInook />
						</RequireAuth>
					}
				/>
				<Route
					exact
					path='/write/continueDraft'
					element={
						<RequireAuth>
							<WriteContinueDraft />
						</RequireAuth>
					}
				/>
				<Route
					exact
					path='/write/inook/:id'
					element={
						<RequireAuth>
							<WriteContinueChapter />
						</RequireAuth>
					}
				/>
				<Route
					exact
					path='/write/draft/:id'
					element={
						<RequireAuth>
							<WriteDraftChapter />
						</RequireAuth>
					}
				/>
				<Route
					exact
					path='/writer/:bookId/:chapterId'
					element={
						<RequireAuth>
							<Writer />
						</RequireAuth>
					}
				/>
				<Route
					exact
					path='/writer'
					element={
						<RequireAuth>
							<Editor />
						</RequireAuth>
					}
				/>
				<Route
					exact
					path='/shareInook/:id'
					element={
						<RequireAuth>
							<ShareInook />
						</RequireAuth>
					}
				/>
				<Route
					exact
					path='/read'
					element={
						<RequireAuth>
							<Read />
						</RequireAuth>
					}
				/>

				<Route
					exact
					path='/readBook/:id'
					element={
						<RequireAuth>
							<ReadBook />
						</RequireAuth>
					}
				/>

				<Route
					exact
					path='/writer/savedraft'
					element={
						<RequireAuth>
							<WriteSaveDraft />
						</RequireAuth>
					}
				/>
			</Routes>
		</Router>
	);
}

export default App;
