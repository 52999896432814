import React, { useEffect, useState } from "react";
import inook from "../img/Login/inook.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "../css/Login.css";
import BASE_URL from "./Constants";
import Modal from "./Modal";
import Loading from "./Loading";
import { useSelector, useDispatch } from "react-redux";
import { changeLoadingState } from "../features/loadingState";
import { addUserToken } from "../features/userData";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Checkbox, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import planet from "../img/Login/planet.png";
import Form from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import api from "../api";
import axios from "axios";

function Login() {
	const isLoadingGlobal = useSelector((state) => state.loadingState.value);
	const dispatchLoadingState = useDispatch();
	const dispatchUserToken = useDispatch();

	const [isLoadingLocal, setIsLoadingLocal] = useState(isLoadingGlobal);
	setTimeout(() => {
		setIsLoadingLocal(false);
	}, 1900);

	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [isEyePasswordShown, setEyePassowordShown] = useState(false);

	const [isOpen, setIsOpen] = useState(false);

	const [saveCredentials, setSaveCredentials] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		if (localStorage.getItem("usr") !== null) {
			setUsername(localStorage.getItem("usr"));
		}
		if (localStorage.getItem("psw") !== null) {
			setPassword(localStorage.getItem("psw"));
		}

		if (localStorage.getItem("token") !== null) {
			navigate("/home");
		}

		if (localStorage.getItem("psw") && localStorage.getItem("usr")) {
			setSaveCredentials(true);
		}
	}, []);

	let handleSubmit = async (e) => {
		e.preventDefault();

		const data = {
			username: username,
			password: password,
		};
		try {
			let res = await axios.post(`${BASE_URL}/auth/login`, data);
			console.log(res.data);
			if (res.status === 200) {
				localStorage.setItem("usr", username);
				localStorage.setItem("psw", password);
				localStorage.setItem("id", res.data.data.id);
				setUsername("");
				setPassword("");
				localStorage.setItem("token", res.data.token);
				dispatchUserToken(addUserToken(res.data.token));
				dispatchLoadingState(changeLoadingState(true));
				navigate("/home");
			} else {
				setUsername("");
				setPassword("");
			}
		} catch (error) {
			console.log(error);
			setUsername("");
			setPassword("");
			setIsOpen(true);
		}
	};

	let handleEyeClick = () => {
		if (isEyePasswordShown === false) {
			setEyePassowordShown(true);
		} else if (isEyePasswordShown === true) {
			setEyePassowordShown(false);
		}
	};
	// HTML

	const login = (
		<Grid
			container
			direction={"row"}
			justifyContent={"space-around"}
			alignItems={"stretch"}
			spacing={0}
			height={"100vh"}
		>
			<Grid xs={6}>
				<Box
					sx={{ flexGrow: 1 }}
					height={"100vh"}
					width={"100%"}
					justifyContent={"center"}
					alignContent={"center"}
					flexDirection={"column"}
				>
					<div className='left-grid-background'>
						<img
							src={planet}
							alt='planet'
							width={"100%"}
							height={"auto"}
						/>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-around",
								alignItems: "center",
								width: "70%",
								marginTop: "-50px",
							}}
						>
							<a
								href='https://twitch.tv/inookcloud'
								target='_blank'
								style={{ color: "#000" }}
								rel='noreferrer'
							>
								Twitch
							</a>

							<a
								href='https://www.instagram.com/inook.cloud?igsh=ZHgzMWFyMXRjd2ll&utm_source=qr'
								target='_blank'
								style={{ color: "#000" }}
								rel='noreferrer'
							>
								Instagram
							</a>

							<a
								href='https://discord.gg/S3Vs4aVhAQ'
								target='_blank'
								style={{ color: "#000" }}
								rel='noreferrer'
							>
								Discord
							</a>

							<a
								href='https://t.me/+N2yWGaI_9IIxYzU8'
								target='_blank'
								style={{ color: "#000" }}
								rel='noreferrer'
							>
								Telegram
							</a>
						</div>
					</div>
				</Box>
			</Grid>
			<Grid xs={6}>
				<Box
					sx={{ flexGrow: 1 }}
					display={"flex"}
					justifyContent={"center"}
					alignContent={"center"}
					alignItems={"center"}
					flexDirection={"column"}
					height={"100vh"}
				>
					<img
						src={inook}
						alt='inook'
						width={"50%"}
						style={{ marginBottom: "50px", marginTop: 50 }}
						height={"auto"}
					/>
					<Form
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "",
							marginTop: "20px",
							alignItems: "center",
							height: "100%",
							border: "30px solid #f39ea6",
							borderRadius: "160px",
							width: "70%",
							maxWidth: "800px",
							minWidth: "600px",
							marginBottom: "50px",
						}}
					>
						<div className='login-from'>
							<div style={{ fontSize: 58, fontWeight: 600 }}>LOG-IN</div>
						</div>
						<div style={{ width: "100%", padding: 16 }}>
							<Grid
								flexDirection={"row"}
								display={"flex"}
							>
								<Grid
									item
									xs={5}
									flexDirection={"column"}
									display={"flex"}
								>
									<h2>User-name</h2>
								</Grid>
								<Grid
									item
									xs={7}
									flexDirection={"row"}
									display={"flex"}
								>
									<TextField
										required
										sx={{ border: "none", "& fieldset": { border: "none" } }}
										id='outlined'
										value={username}
										onChange={(e) => setUsername(e.target.value)}
										fullWidth
										style={{
											backgroundColor: "#f4e9eb",
											border: "0px !important",
										}}
									/>
								</Grid>
							</Grid>
							<Grid
								flexDirection={"row"}
								display={"flex"}
								marginTop={"20px"}
							>
								<Grid
									item
									xs={5}
									flexDirection={"column"}
									display={"flex"}
								>
									<h2>Password</h2>
								</Grid>
								<Grid
									item
									xs={7}
									flexDirection={"row"}
									display={"flex"}
								>
									<OutlinedInput
										id='outlined-adornment-password'
										value={password}
										fullWidth
										style={{
											backgroundColor: "#f4e9eb",
											border: "0px !important",
										}}
										sx={{ border: "none", "& fieldset": { border: "none" } }}
										onChange={(e) => setPassword(e.target.value)}
										type={isEyePasswordShown ? "text" : "password"}
										endAdornment={
											<InputAdornment position='end'>
												<IconButton
													aria-label='toggle password visibility'
													onClick={handleEyeClick}
													onMouseDown={handleEyeClick}
													edge='end'
												>
													{isEyePasswordShown ? (
														<VisibilityOff />
													) : (
														<Visibility />
													)}
												</IconButton>
											</InputAdornment>
										}
										label='Password'
									/>
								</Grid>
							</Grid>
							<Checkbox
								style={{ color: "#f39ea6" }}
								checked={saveCredentials}
								onChange={() => setSaveCredentials(!saveCredentials)}
							/>
							<span>Salva le crendenziali</span>
						</div>
						<Button
							variant='outlined'
							style={{
								border: "5px solid #f39ea6",
								color: "#000",
								fontSize: "25px",
								borderRadius: "28px",
								textTransform: "none",
								padding: "0px 23px 0px 24px",
								fontWeight: 600,
								height: "auto",
							}}
							onSubmit={handleSubmit}
							onClick={handleSubmit}
						>
							Accedi
						</Button>
						<div
							style={{
								marginTop: 30,
								marginBottom: 30,
								display: "flex",
								flexDirection: "column",
							}}
						>
							<span>
								Non sei registrato ?{" "}
								<Link
									className='link'
									to={"/register"}
								>
									Sign-in!
								</Link>
							</span>
							<span>
								Non ricordi la password?{" "}
								<Link
									className='link'
									to={"/reset"}
								>
									Clicca qui
								</Link>
							</span>
						</div>
					</Form>
				</Box>
			</Grid>
			{isOpen && (
				<Modal
					title='Attenzione!'
					body='Username e/o password errate...'
					buttonText='Riprova'
					onClose={() => setIsOpen(false)}
					onClick={() => setIsOpen(false)}
				/>
			)}
		</Grid>
	);

	return <div>{isLoadingLocal ? <Loading /> : login}</div>;
}

export default Login;
