import React from "react";
import { useState } from "react";
import Header from "./Header";
import Modal from "./Modal";
import Loading from "./Loading";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changeLoadingState } from "../features/loadingState";
import { changeModalState } from "../features/modalState";
import ModProfileMenu from "./ModProfileMenu";

function ModProfile() {
	const isLoadingGlobal = useSelector((state) => state.loadingState.value);
	const isModalGlobal = useSelector((state) => state.modalState.value);
	const dispatchLoadingState = useDispatch();
	const dispatchModalState = useDispatch();
	const navigate = useNavigate();

	const [isLoadingLocal, setIsLoadingLocal] = useState(isLoadingGlobal);
	setTimeout(() => {
		setIsLoadingLocal(false);
	}, 1900);

	function handleClick(message) {
		if (message === "success") {
			dispatchModalState(changeModalState(""));
			dispatchLoadingState(changeLoadingState(true));
			navigate("/profile");
		} else if (message === "error") {
			dispatchModalState(changeModalState(""));
		} else if (message === "fail") {
			dispatchModalState(changeModalState(""));
		}
	}

	const Profile = (
		<div>
			<Header
				title='PROFILO'
				to='/'
			/>
			<ModProfileMenu />

			{isModalGlobal === "password" ? (
				<Modal
					style={{ top: "0", zIndex: "1" }}
					title='Attenzione!'
					body='La vecchia password inserita non corrisponde con quella registrata nei nostri sistemi. Riprovare oppure effettuare il processo di reset password.'
					buttonText='Riprova'
					onClose={() => handleClick("fail")}
					onClick={() => handleClick("fail")}
				/>
			) : (
				""
			)}

			{isModalGlobal === "success" ? (
				<Modal
					style={{ top: "0", zIndex: "1" }}
					title='Fantastico!'
					body='I tuoi dati sono stati aggiornati con successo. Torna al tuo profilo.'
					buttonText='Continua...'
					onClose={() => handleClick("success")}
					onClick={() => handleClick("success")}
				/>
			) : (
				""
			)}

			{isModalGlobal === "error" ? (
				<Modal
					style={{ top: "0", zIndex: "1" }}
					title='Ops..'
					body="Il processo di aggiornamento dati non è andato a buon fine. Prova a inserire un'immagine meno pesante."
					buttonText='Riprova'
					onClose={() => handleClick("error")}
					onClick={() => handleClick("error")}
				/>
			) : (
				""
			)}

			{isModalGlobal === "fail" ? (
				<Modal
					style={{ top: "0", zIndex: "1" }}
					title='Attenzione!'
					body='I campi password e conferma password risultano diversi, prova a reinserire entrambi i valori correttamente.'
					buttonText='Riprova'
					onClose={() => handleClick("fail")}
					onClick={() => handleClick("fail")}
				/>
			) : (
				""
			)}
		</div>
	);

	return <div>{isLoadingLocal ? <Loading /> : Profile}</div>;
}

export default ModProfile;
