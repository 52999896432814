import React, { useEffect } from "react";
import Header from "./Header";
import NavMenu from "./NavMenu";
import Modal from "./Modal";
import Loading from "./Loading";
import "../css/WriteContinue.css";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changeLoadingState } from "../features/loadingState";
import BASE_URL from "./Constants";
import { Delete } from "@mui/icons-material";
import Fab from "@mui/material/Fab";
import { Share, Send } from "@mui/icons-material";
import { Tooltip, Box, Button as ButtonMui } from "@mui/material";
import { Modal as ModalMui } from "@mui/material";
import api from "../api";
function WriteContinueInook() {
	const userCode = localStorage.getItem("id");
	const [publish, setPublish] = useState(false);
	const [bookList, setBookList] = useState([]);
	const [modalDraft, setModalDraft] = useState(false);
	const [bookSelected, setBookSelected] = useState(null);
	useEffect(() => {
		grabBooks();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isLoggedIn = localStorage.getItem("isLoggedIn");

	const isLoadingGlobal = useSelector((state) => state.loadingState.value);
	const dispatchLoadingState = useDispatch();
	const navigate = useNavigate();
	const [isLoadingLocal, setIsLoadingLocal] = useState(isLoadingGlobal);
	setTimeout(() => {
		setIsLoadingLocal(false);
	}, 1900);

	const deleteInook = async (id) => {
		try {
			await api.delete(`${BASE_URL}/book/delete/${id}`);
			grabBooks();
		} catch (error) {
			console.log(error);
		}
	};

	let handleClickRedirecting = (id) => {
		dispatchLoadingState(changeLoadingState(true));
		navigate(`/write/inook/${id}`);
	};

	let grabBooks = async () => {
		try {
			let res = await api.get(`${BASE_URL}/book/getAllByUser/${userCode}`);
			setBookList(res.data.data);
		} catch (error) {
			console.log(error);
		}
	};

	const setDraftBook = (id) => {
		setBookSelected(id);
		setModalDraft(true);
	};

	const grabChapterData = async (id) => {
		try {
			let res = await api.get(`${BASE_URL}/chapter/getByBookId/${id}`);
			const arrayChapter = [];
			res.data.data.forEach((chapter) => {
				arrayChapter.push({ id: chapter.id, checked: false });
			});

			const book = bookList?.filter((book) => book.id === id)[0];
			const cod_user = localStorage.getItem("id");

			const data = {
				id: id,
				title: book.title,
				description: book.description,
				cod_category: book.cod_category,
				cod_user: cod_user,
				enabled: 0,
				chapter: arrayChapter,
			};

			try {
				await api.post(`${BASE_URL}/book/publish`, data).then((res) => {
					console.log(res);
				});
				grabBooks();
				setModalDraft(false);
			} catch (error) {}
		} catch (error) {
			console.log(error);
		}
	};

	const doUnpublish = async () => {
		grabChapterData(bookSelected);
	};

	const publishInook = async () => {
		try {
			await api.get(`${BASE_URL}/book/publishAllByUser/${userCode}`);
			grabBooks();
		} catch (error) {
			console.log(error);
		}
	};

	let writeContinue = (
		<div>
			<Header
				title='I MIEI INOOK'
				to='/home'
				action={() => publishInook()}
				share={true}
			/>
			<div className='baseWrapper'>
				<NavMenu />
				<div
					className='choicesWrapper'
					style={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					{bookList?.length > 0 ? (
						<div>
							{bookList.map((inook) => {
								return (
									<div>
										<div
											className={"choiseWrite"}
											style={{
												display: "flex",
												justifyContent: "space-between",
												padding: "0px 10px",
												alignItems: "center",
											}}
										>
											<div
												style={{
													display: "flex",
													flexDirection: "column",
													justifyContent: "flex-start",
													alignItems: "flex-start",
													width: "100%",
												}}
											>
												<div
													style={{
														justifyContent: "space-between",
														display: "flex",
														alignContent: "center",
														alignItems: "center",
														width: "100%",
														flexDirection: "row",
													}}
												>
													<span
														style={{
															cursor: "pointer",
															fontSize: 20,
															marginLeft: 5,
														}}
														onClick={() => handleClickRedirecting(inook.id)}
													>
														{inook.title}
													</span>
													<span
														style={{ cursor: "pointer" }}
														onClick={() => deleteInook(inook.id)}
													>
														<Delete
															style={{
																width: 50,
																height: 30,
															}}
															htmlColor='#f29da5'
														/>
													</span>
												</div>
												<span
													style={{
														fontSize: 15,
														padding: "0 5px",
														marginTop: -10,
														marginBottom: 10,
													}}
												>
													{inook?.enabled === 0 ? (
														<>
															<span>Inook non pubblicato</span>
															{" | "}
															<span
																style={{
																	color: "#f19da6",
																	cursor: "pointer",
																	textDecoration: "underline",
																}}
																onClick={() =>
																	navigate(`/write/publishInook/${inook.id}`)
																}
															>
																Vuoi pubblicarlo?
															</span>
														</>
													) : (
														<>
															<span>Inook pubblicato</span>
															{" | "}
															<span
																style={{
																	color: "#f19da6",
																	cursor: "pointer",
																	textDecoration: "underline",
																}}
																onClick={() => setDraftBook(inook.id)}
															>
																Vuoi spostarlo in bozze?
															</span>
														</>
													)}
												</span>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					) : (
						<>
							<h2>Non hai ancora creato nessun Inook</h2>
							<button
								className='button'
								onClick={() => navigate("/write/newInook")}
							>
								Creane uno
							</button>
						</>
					)}
				</div>
			</div>
			<Tooltip title='Pubblica'>
				{!publish ? (
					<Fab
						className='fab'
						sx={{
							position: "fixed",
							bottom: 16,
							right: 16,
							backgroundColor: "#f29da5",
							color: "#fff",
						}}
						color='#f29da5'
						aria-label='add'
						onClick={() => {
							setPublish(true);
						}}
					>
						<Send
							color='#fff'
							onClick={() => setPublish(true)}
						/>
					</Fab>
				) : (
					<Fab
						className='fab'
						sx={{
							position: "fixed",
							bottom: 16,
							right: 16,
							backgroundColor: "#f29da5",
							color: "#fff",
						}}
						color='#f29da5'
						aria-label='add'
						onClick={() => setPublish(false)}
					>
						<Share
							color='#fff'
							onClick={() => setPublish(false)}
						/>
					</Fab>
				)}
			</Tooltip>
		</div>
	);

	return (
		<div>
			{writeContinue}
			<ModalMui
				open={modalDraft}
				onClose={() => setModalDraft(false)}
				style={{ outline: 0 }}
				centered
			>
				<Box
					className='modalChapter'
					sx={{
						width: "60vw",
						height: "100%",
						maxHeight: "30vh",
						marginLeft: "20vw",

						marginRight: "20vw",
						marginTop: "20vh",
						display: "flex",
						flexDirection: "column",
						borderRadius: "30px",
						justifyContent: "space-between",

						padding: "30px",
						backgroundColor: "#fff",
						border: "none",
					}}
				>
					<div
						style={{
							justifyContent: "center",
							alignItems: "center",
							display: "flex",
							flexDirection: "column",
						}}
					>
						<h2 id='parent-modal-title'>Rimuovi libro</h2>
						<p id='parent-modal-description'>
							Vuoi spostare il tuo libro da pubblicato alla sezione bozze?{" "}
							<br />
							Se confermi anche tutti i capitoli pubblicati verranno spostati in
							bozze.
						</p>
					</div>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							width: "60%",
							marginLeft: "20%",
							marginRight: "20%",
						}}
					>
						<ButtonMui
							onClick={() => setModalDraft(false)}
							style={{
								backgroundColor: "#fff",
								color: "#f29da5",
								marginTop: 20,
								marginBottom: 20,
								width: "40%",
								height: "50px",
								borderRadius: "30px",
								border: "1px solid #f29da5",
								fontSize: "20px",
								fontWeight: "bold",
							}}
						>
							Annulla
						</ButtonMui>
						<ButtonMui
							onClick={doUnpublish}
							style={{
								backgroundColor: "#f29da5",
								color: "#fff",
								marginTop: 20,
								marginBottom: 20,
								width: "40%",
								height: "50px",
								borderRadius: "30px",
								fontSize: "20px",
								fontWeight: "bold",
							}}
						>
							Conferma
						</ButtonMui>
					</div>
				</Box>
			</ModalMui>
		</div>
	);
}

export default WriteContinueInook;
