import React from "react";
import { useState } from "react";
import Header from "./Header";
import NavMenu from "./NavMenu";
import Loading from "./Loading";
import "../css/Home.css";
import inookBook from "../img/Home/inookBook.png";
import inookPencil from "../img/Home/inookPencil.png";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changeLoadingState } from "../features/loadingState";

function Home() {
	const isLoadingGlobal = useSelector((state) => state.loadingState.value);
	const dispatchLoadingState = useDispatch();
	const navigate = useNavigate();

	const [isLoadingLocal, setIsLoadingLocal] = useState(isLoadingGlobal);
	setTimeout(() => {
		setIsLoadingLocal(false);
	}, 1900);

	let handleClickRedirecting = (path) => {
		dispatchLoadingState(changeLoadingState(true));
		navigate(path);
	};

	let home = (
		<div>
			<Header
				title='HOME PAGE'
				to='/home'
			/>
			<div className='baseWrapper'>
				<NavMenu onClick={() => handleClickRedirecting()} />
				<div className='choicesWrapper'>
					<div className='homeInookerWrapper'>
						<h1 className='homeTitleInooker'>
							Benvenuto inooker! <br />
							Sei pronto?
						</h1>
					</div>
					<div className='homeButtonsWrapper'>
						<button
							className='homeWriteButton'
							onClick={() => handleClickRedirecting("/write")}
						>
							Scrivi
							<img
								src={inookPencil}
								className='homePencilIcon'
								alt='pencil icon'
							></img>
						</button>
						<button
							className='homeReadButton'
							onClick={() => handleClickRedirecting("/read")}
						>
							Leggi
							<img
								src={inookBook}
								className='homeBookIcon'
								alt='book icon'
							></img>
						</button>
					</div>
				</div>
			</div>
		</div>
	);

	return <div>{isLoadingLocal ? <Loading /> : home}</div>;
}

export default Home;
