import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../css/ModProfileMenu.css";
import SignUpInputReadOnly from "./SignUpInputReadOnly";

function ProfileMenu({ data }) {
	const [imageUrl] = useState(data?.imgPath);

	const [username, setUsername] = useState(data?.username);
	const [name, setName] = useState(data?.name);
	const [surname, setSurname] = useState(data?.surname);
	const [date, setDate] = useState(data?.date);
	const [email, setEmail] = useState(data?.email);
	const navigate = useNavigate();

	return (
		<div className='containerProfile'>
			<div className='col1Profile'>
				<div
					className='imageUploadedProfile'
					style={{
						backgroundImage: `url(${imageUrl}?v=${Date.now()})`,
						cursor: "default",
						objectFit: "cover",
						backgroundSize: "cover",
						backgroundPosition: "center",
					}}
				></div>
			</div>
			<div className='col2Profile'>
				<label
					id='big-inputProfile'
					htmlFor='big-inputProfile'
				>
					Username
				</label>
				<SignUpInputReadOnly
					id='big-inputProfile'
					className='BIG SignUpInput-containerProfile'
					name='username'
					type='text'
					onChange={(e) => setUsername(e.target.value)}
					value={username || ""}
				/>
				<SignUpInputReadOnly
					className='first SignUpInput-containerProfile'
					name='name'
					placeholder='Nome'
					type='text'
					onChange={(e) => setName(e.target.value)}
					value={name || ""}
				/>
				<SignUpInputReadOnly
					className='SignUpInput-containerProfile'
					name='surname'
					placeholder='Cognome'
					type='text'
					onChange={(e) => setSurname(e.target.value)}
					value={surname || ""}
				/>
				<SignUpInputReadOnly
					className='SignUpInput-containerProfile'
					name='birthdate'
					placeholder='Data di nascita'
					type='text'
					onChange={(e) => setDate(e.target.value)}
					value={date || ""}
				/>
				<SignUpInputReadOnly
					className='SignUpInput-containerProfile'
					name='email'
					placeholder=''
					type='text'
					onChange={(e) => setEmail(e.target.value)}
					value={email || ""}
				/>
			</div>
			<div className='col3Profile'>
				<button
					className='submit-borderProfile'
					style={{ marginTop: "30vh" }}
					onClick={() => navigate("/modprofile")}
				>
					Modifica
				</button>
			</div>
		</div>
	);
}

export default ProfileMenu;
