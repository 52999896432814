import React from "react";
import "../css/Modal.css";

const Modal = (props) => {
	return (
		<div
			className='modalBackground'
			style={props.style}
		>
			<div className='modalContainer'>
				<div className='modalTitle'>
					{/* <h1>{props.title}</h1> */}
					<h1>{props.title}</h1>
					<button
						onClick={props.onClose}
						className='titleCloseBtn'
					>
						{" "}
						X{" "}
					</button>
				</div>

				<div className='modalBody'>
					<p>{props.body}</p>
				</div>
				<div className='modalFooter'>
					{props?.buttonText && (
						<button onClick={props.onClick}>{props.buttonText}</button>
					)}
					{props?.buttonTextOk && (
						<button onClick={props.onClickOk}>{props.buttonTextOk}</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default Modal;
