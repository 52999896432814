import React, { useEffect } from "react";
import Header from "./Header";
import NavMenu from "./NavMenu";
import Button from "./Button";
import Modal from "./Modal";
import Loading from "./Loading";
import "../css/WriteContinue.css";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changeLoadingState } from "../features/loadingState";
import BASE_URL from "./Constants";
import api from "../api";

function WriteContinueDraft() {
	const userCode = localStorage.getItem("id");

	const [bookList, setBookList] = useState([]);

	useEffect(() => {
		grabBooks();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isLoggedIn = localStorage.getItem("isLoggedIn");

	const isLoadingGlobal = useSelector((state) => state.loadingState.value);
	const dispatchLoadingState = useDispatch();
	const navigate = useNavigate();

	const [isLoadingLocal, setIsLoadingLocal] = useState(isLoadingGlobal);
	setTimeout(() => {
		setIsLoadingLocal(false);
	}, 1900);

	let handleClickRedirecting = (id) => {
		dispatchLoadingState(changeLoadingState(true));
		navigate(`/write/draft/${id}`);
	};

	let grabBooks = async () => {
		try {
			let res = await api.get(`${BASE_URL}/book/getAllByUserDraft/${userCode}`);
			setBookList(res.data.data);
		} catch (error) {
			console.log(error);
		}
	};

	let writeContinue = (
		<div>
			<Header
				title='I MIEI INOOK'
				to='/home'
			/>
			<div className='baseWrapper'>
				<NavMenu />
				<div className='choicesWrapper'>
					{bookList.map((inook) => {
						return (
							<Button
								key={inook.id}
								href=''
								name={inook.title}
								className='choiseWrite'
								onClick={() => handleClickRedirecting(inook.id)}
							/>
						);
					})}
				</div>
			</div>
		</div>
	);

	return writeContinue;
}

export default WriteContinueDraft;
