import React from 'react';
import ReactDOM from 'react-dom/client';
import App from "./components/App";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from 'react-redux';
import userTokenReducer from "./features/userData";
import loadingReducer from "./features/loadingState";
import modalReducer from "./features/modalState";
import userMailReducer from "./features/userMail";
import 'font-awesome/css/font-awesome.min.css';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
//import AlertTemplate from 'react-alert-template-mui'
import AlertTemplate from './AlertTemplate'

const store = configureStore({
  // Conterrà tutte le variabili che devono essere 
  // contenute all'interno dello store globale.
  reducer: {
    userToken: userTokenReducer,
    userMail: userMailReducer,
    loadingState: loadingReducer,
    modalState: modalReducer,
  }
})




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

      <Provider store={store}>
        <App />
      </Provider>


);

