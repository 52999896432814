import React, { useRef } from "react";
import "../css/Editor.css";
import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const TextEditor = (props) => {
	const refQuill = useRef(null);
	return (
		<ReactQuill
			ref={refQuill}
			theme='snow'
			readOnly={false}
			value={props.value}
			placeholder='Scrivi qui il tuo inook...'
			scrollingContainer={true}
			onChange={(e) => {
				props.setValue(e);
			}}
		/>
	);
};

export default TextEditor;
