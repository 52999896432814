import axios from "axios";
import BASE_URL from "../components/Constants";

function getToken() {
	const token = localStorage.getItem("token");
	if (!token) {
		console.log("Token not found, handling case...");
		// Redirect to login or another appropriate action
		return ""; // return an empty string or a default token if necessary
	}
	console.log("Token found: " + token);
	return token;
}

const api = axios.create({
	baseURL: BASE_URL,
	headers: {
		"Content-Type": "application/json",
	},
});

api.interceptors.request.use(
	(config) => {
		const token = getToken();
		if (token) {
			config.headers["Authorization"] = "Bearer " + token;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

export default api;
