import React, { useEffect, useState } from "react";
import Header from "./Header";
import BASE_URL from "./Constants";
import { useNavigate, useParams } from "react-router-dom";
import "./shareInook.css";
import shareContent from "../img/shareContent/logo.png";
import ReactCurvedText from "react-curved-text";
import { Button } from "@mui/material";
import api from "../api";

function ShareInook() {
	const { id } = useParams();
	const [dataBook, setDataBook] = useState({});
	const navigate = useNavigate();

	async function getBook() {
		const res = await api.get(`${BASE_URL}/book/getById/${id}`);
		setDataBook(res.data.data?.[0]);
	}

	function copyClipboard() {
		var TextToCopy = window.location.origin + "/readBook/" + id;
		var TempText = document.createElement("input");
		TempText.value = TextToCopy;
		document.body.appendChild(TempText);
		TempText.select();

		document.execCommand("copy");
		document.body.removeChild(TempText);

		alert("Link copiato");
	}

	useEffect(() => {
		getBook();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<div>
				<Header
					title='CONDIVIDI CON I TUOI AMICI'
					to='/home'
				/>
				<div className='newBookWrapper'>
					<div className='newBookFirstCol'>
						<div
							style={{
								width: "100%",
								height: "100%",
								justifyContent: "center",
								marginLeft: "5vw",
								alignContent: "center",
								alignItems: "center",
								display: "flex",
								flexDirection: "column",
								marginTop: "-40px",
							}}
						>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
									marginTop: "120px",
								}}
							>
								<ReactCurvedText
									width={350}
									height={180}
									cx={200}
									cy={200}
									rx={180}
									ry={100}
									startOffset={60}
									reversed={true}
									text='Congratulazioni!'
									textProps={{
										style: {
											fontSize: 40,
											fontWeight: "bold",
											marginLeft: "-20px",
											marginTop: "-20px",
										},
									}}
									textPathProps={null}
									tspanProps={null}
									ellipseProps={null}
									svgProps={null}
								/>
								<div
									style={{
										marginLeft: "30px",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
										marginTop: "-130px",
									}}
								>
									<img
										src={shareContent}
										style={{ width: "30%", marginBottom: "2vh" }}
										alt='shareContent'
									/>
									<img
										alt='plusButton'
										src={dataBook.imgPath}
										style={{
											borderRadius: "60px",
											border: "20px solid #f29da5",
											maxHeight: "60%",
											width: "70%",
											aspectRatio: "1/2",
											objectFit: "cover",
											minWidth: "70%",
										}}
									/>
									<span style={{ fontSize: 30, marginTop: 30 }}>
										Hai pubblicato il tuo{" "}
										<span style={{ color: "#f29da5" }}> Inook!</span>
									</span>
								</div>
							</div>
						</div>
					</div>
					<div className='newBookSecondCol'>
						<div
							style={{
								width: "100%",
								height: "100%",
								justifyContent: "center",
								marginLeft: "5vw",
								alignContent: "center",
								alignItems: "center",
								display: "flex",
								flexDirection: "column",
							}}
						>
							<Button
								style={{
									border: "solid 21.1px #f29ca4",
									borderRadius: 200,
									padding: "5px 5px",
									maxWidth: "50%",
									textTransform: "none",
									fontSize: "30px",
									textAlign: "center",
									fontWeight: "bold",
									color: "#000",
									width: "50%",
									height: "164px",
								}}
								onClick={copyClipboard}
							>
								Condividilo <br />
								con gli amici!
							</Button>
							<Button
								style={{
									border: "solid 21.1px #f29ca4",
									borderRadius: 200,
									padding: "5px 5px",
									maxWidth: "50%",
									textTransform: "none",
									fontSize: "30px",
									textAlign: "center",
									fontWeight: "bold",
									color: "#000",
									width: "50%",
									height: "164px",
									marginTop: "10vh",
								}}
								onClick={() => navigate("/home")}
							>
								Torna alla sezione <br />
								"il tuo spazio"
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ShareInook;
