import { createSlice } from "@reduxjs/toolkit";

const initialState = "";

export const ModalSlice = createSlice({
    name: "ModalState",
    initialState: { value: initialState},
    reducers: {
        changeModalState: (state, action) => {
            state.value = action.payload;
        }
    }
})

export const { changeModalState } = ModalSlice.actions;

export default ModalSlice.reducer;