import React, { useEffect } from "react";
import { useState } from "react";
import Header from "./Header";
import Loading from "./Loading";
import Modal from "./Modal";
import "../css/NewBook.css";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import BASE_URL from "./Constants";
import plusButton from "../img/inputImages/plusButton.png";
import "../css/Dropdown.css";
import { Button, Modal as ModalMui } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import api from "../api";
import CustomAlert from "./Alert";
function NewBook() {
	const { id } = useParams();
	const [modal, setModal] = useState(false);
	const isLoggedIn = localStorage.getItem("isLoggedIn");
	const [selectedChapterId, setSelectedChapterId] = useState([]);
	const isLoadingGlobal = useSelector((state) => state.loadingState.value);
	const navigate = useNavigate();
	const cod_user = localStorage.getItem("id");

	let [newInookData, setNewInookData] = useState({
		cod_user: cod_user,
		title: "",
		description: "",
		category: "",
		imgPath: "default",
		enabled: 0, // 0 di default;
	});

	useEffect(() => {
		if (id) {
			api.get(`${BASE_URL}/book/getById/${id}`).then((res) => {
				const data = res.data.data?.[0];
				setNewInookData({
					title: data?.title,
					description: data?.description,
					category: data?.cod_category,
					imgPath: data?.imgPath,
					enabled: data?.enabled,
				});
				setSelectedValue({
					value: data.cod_category,
					label: data.category,
				});
				setFile(data.imgPath);
			});
		}
	}, [id]);

	const [isLoadingLocal, setIsLoadingLocal] = useState(isLoadingGlobal);
	setTimeout(() => {
		setIsLoadingLocal(false);
	}, 1900);

	let handleChange = (e) => {
		const { name, value } = e.target;

		setNewInookData((prevState) => {
			return {
				...prevState,
				[name]: value,
			};
		});
	};

	let handleFormSubmit = async (e) => {
		e.preventDefault();
		const data = {
			id: id,
			title: newInookData.title,
			description: newInookData.description,
			cod_category: selectedValue.value,
			cod_user: cod_user,
			enabled: 1,
			imgPath: image64,
			chapter: selectedChapterId,
		};

		const selectedChapterIdFiltered = data?.chapter?.filter(
			(el) => el?.checked === true
		);
		if (selectedChapterIdFiltered.length === 0) {
			CustomAlert({
				title: "Errore",
				body: "Seleziona almeno un capitolo da pubblicare",
				cancelButton: "Ok",
				type: "error",
			});

			return;
		}
		setIsLoadingLocal(true);

		try {
			await api.post(`${BASE_URL}/book/publish`, data).then((res) => {
				setTimeout(() => {
					setIsLoadingLocal(false);
					navigate(`/shareInook/${id}`);
				}, 1900);
			});
		} catch (error) {
			console.log(error);
			setIsLoadingLocal(false);
		}

		// dispatchLoadingState(changeLoadingState(true));
	};

	const [showMenu, setShowMenu] = useState("");
	const [selectedValue, setSelectedValue] = useState(null);
	const [chapterData, setChapterData] = useState(null);
	const handleInputClick = (e) => {
		e.stopPropagation();
		if (showMenu === "") {
			setShowMenu("showMenu");
		} else {
			setShowMenu("");
		}
	};

	useEffect(() => {
		if (id) {
			api.get(`${BASE_URL}/chapter/getAllChapterByBookId/${id}`).then((res) => {
				console.log(res);
				setChapterData(res.data.data);
				const arrayChapter = [];
				res.data.data.map((el) =>
					arrayChapter.push({
						id: el.id,
						checked: el?.enabled === 1 ? true : false,
					})
				);
				setSelectedChapterId(arrayChapter);
			});
		}
	}, [id]);

	const getDisplay = () => {
		if (selectedValue) {
			return selectedValue.label;
		}

		const placeHolder = "Seleziona un genere";
		return placeHolder;
	};

	const onItemClick = (option) => {
		setSelectedValue(option);
	};

	const isSelected = (option) => {
		if (!selectedValue) {
			return false;
		}

		return selectedValue.value === option.value;
	};

	const Icon = () => {
		return (
			<svg
				height='20'
				width='20'
				viewBox='0 0 20 20'
			>
				<path d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'></path>
			</svg>
		);
	};

	const [category, setCategory] = useState([]);
	const [file, setFile] = useState();
	const [image64, setImage64] = useState();
	function uploadFile(e) {
		const image = e.target.files[0];
		const dimension = image.size / 1000000;
		if (dimension > 8) {
			CustomAlert({
				title: "Attenzione!",
				body: "File troppo grande, dimensione massima 8MB",
				cancelButton: "Ok",
				type: "error",
			});
			return;
		}
		setFile(URL.createObjectURL(image));
		const reader = new FileReader();
		reader.readAsDataURL(image);
		reader.onload = () => {
			const base64 = reader.result;
			setImage64(base64);
		};
		reader.onerror = (error) => {
			console.log("Error: ", error);
		};
	}

	useEffect(() => {
		api.get(`${BASE_URL}/category/getAll`).then((res) => {
			const categories = res.data?.data?.map((item) => ({
				value: item.id,
				label: item.title,
			}));
			console.log(categories);
			setCategory(categories);
		});
	}, []);

	const dropdown = (
		<div
			onClick={handleInputClick}
			className='dropdown-container'
		>
			<div className='dropdown-input'>
				<div className='dropdown-selected-value'>{getDisplay()}</div>
				<div className='dropdown-tools'>
					<div className='dropdown-tool'>
						<Icon />
					</div>
				</div>
				<div className={`dropdown-menu ${showMenu}`}>
					{category.map((option) => (
						<label
							key={option.value}
							htmlFor='categoryInput'
							className={`dropdown-item ${isSelected(option) && "selected"}`}
							onClick={() => onItemClick(option)}
							// vedere sta funzione
						>
							{option.label}
						</label>
					))}
				</div>
			</div>
			<input
				id='categoryInput'
				name='categoryInput'
				type='hidden'
				value={selectedValue !== null ? selectedValue.value : ""}
			></input>
			{/* onChange={props.onChange} */}
		</div>
	);

	let newbookTest = (
		<div>
			<Header
				title='PUBBLICA'
				to='/home'
			/>
			<div className='newBookWrapper'>
				<div className='newBookFirstCol'>
					{/* AGGIUNGERE MECCANICHE DI CARICAMENTO E DISPLAYING DELL'IMMAGINE DI COPERTINA */}
					<input
						id='inputImages'
						name='image'
						type='file'
						accept='.png'
						onChange={uploadFile}
					></input>
					<label
						htmlFor='inputImages'
						className='flexColNewBook'
					>
						{file ? (
							<img
								src={file}
								style={{
									width: "100%",
									maxHeight: "100%",
									objectFit: "cover",
									objectPosition: "center",
								}}
								alt='coverImage'
							/>
						) : (
							<img
								src={plusButton}
								alt='plusButton'
								className='plusButtonNewBook'
							/>
						)}
					</label>
				</div>
				<div className='newBookSecondCol'>
					<p className='newBookParagraph'>
						<strong>
							Perfetto! <br />
							Vuoi cambiare i dati inseriti ? Questo è il momento adatto per
							farlo!
						</strong>
					</p>
					<form
						onSubmit={handleFormSubmit}
						className='newBookForm'
					>
						<input
							type='text'
							name='title'
							className='newBookTitle'
							placeholder='Titolo provvisorio'
							value={newInookData.title}
							onChange={(e) => handleChange(e)}
						/>
						<textarea
							type='text'
							name='description'
							value={newInookData.description}
							className='newBookDescription'
							placeholder='Descrizione provvisoria'
							onChange={(e) => handleChange(e)}
						/>
						<span
							style={{
								cursor: "pointer",
								color: "#f29ca4",
								textDecoration: "underline",
								marginTop: 20,
							}}
							onClick={() => setModal(true)}
						>
							Scegli i capitoli che vuoi pubblicare
						</span>
						<div className='newBookSubmissionWrapper'>
							{dropdown}

							<button
								type='submit'
								className='newBookSubmit'
								style={{ marginTop: 20 }}
								onClick={handleFormSubmit}
								disabled={!isLoadingGlobal}
							>
								<span style={{ marginBottom: 0 }}>Pubblica </span>
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);

	return (
		<div>
			<ModalMui
				open={modal}
				onClose={() => setModal(false)}
				className='modalChapter'
				centered
				style={{ outline: 0 }}
			>
				<Box
					className='modalChapter'
					sx={{
						width: "60vw",
						height: "100%",
						maxHeight: "60vh",
						marginLeft: "20vw",

						marginRight: "20vw",
						marginTop: "20vh",
						display: "flex",
						flexDirection: "column",
						borderRadius: "30px",
						justifyContent: "space-between",

						padding: "30px",
						backgroundColor: "#fff",
						border: "none",
					}}
				>
					<div>
						<h2 id='parent-modal-title'>Seleziona i capitoli</h2>
						<p id='parent-modal-description'>
							In questa sezione potrai decidere quali capitoli pubblicare
							insieme al tuo Inook!
						</p>

						<div
							style={{
								overflowY: "scroll",
								overflowX: "hidden",
								height: "100%",
								maxHeight: "30vh",
							}}
						>
							{chapterData?.map((el) => {
								return (
									<div>
										<Checkbox
											defaultChecked
											sx={{
												color: "#f29da5",
												"&.Mui-checked": {
													color: "#f29da5",
												},
											}}
											onClick={() => {
												const newSelectedChapterId = [...selectedChapterId];
												let index = newSelectedChapterId.findIndex(
													(el2) => el2.id === el.id
												);
												newSelectedChapterId[index].checked =
													!newSelectedChapterId[index].checked;
												setSelectedChapterId(newSelectedChapterId);
											}}
											checked={
												selectedChapterId?.filter(
													(el2) => el2.id === el.id
												)?.[0]?.checked
											}
											inputProps={{ "aria-label": "controlled" }}
										/>
										{el.title}
									</div>
								);
							})}
						</div>
					</div>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							width: "100%",
						}}
					>
						<Button
							onClick={() => setModal(false)}
							style={{
								backgroundColor: "#f29da5",
								color: "#fff",
								marginTop: 20,
								marginBottom: 20,
								width: "60%",
								height: "50px",
								borderRadius: "30px",
								fontSize: "20px",
								fontWeight: "bold",
							}}
						>
							Conferma capitoli
						</Button>
					</div>
				</Box>
			</ModalMui>

			{newbookTest}
		</div>
	);
}

export default NewBook;
