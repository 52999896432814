/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from "react";
import "../css/SignUpMenu.css";
import BASE_URL from "./Constants";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeModalState } from "../features/modalState";
import CustomAlert from "./Alert";
import plusButton from "../img/inputImages/plusButton.png";
import { encode as base64_encode } from "base-64";
import {
	Grid,
	TextField,
	FormControl,
	Checkbox,
	Button,
	OutlinedInput,
	InputAdornment,
	IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import api from "../api";
// 1. AGGIUNGERE SIMBOLO DI IMPORTAZIONE AVATAR UTENTE
// 2. INSERIRE CONTROLLI DI INPUT -> FORMIK
// 3. CONTROLLARE RESPONSIVENESS

function SignUpMenu() {
	const [image, setImage] = useState("");
	const [imageUrl, setImageUrl] = useState("");
	const [image64, setImage64] = useState("");

	const [username, setUsername] = useState("");
	const [name, setName] = useState("");
	const [surname, setSurname] = useState("");
	const [date, setDate] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmation, setConfirmation] = useState("");
	const [isEyePasswordShown, setEyePassowordShown] = useState(false);
	const [isEyePasswordShownConfirm, setEyePassowordShownConfirm] =
		useState(false);

	const dispatchModalState = useDispatch();

	const [newsLetter, setNewsLetter] = useState(false);
	const [privacy, setPrivacy] = useState(false);

	useEffect(() => {
		if (image === "") return;
		setImageUrl(URL.createObjectURL(image));
		console.log(image, URL.createObjectURL(image));
		setImage64(base64_encode(image));
	}, [image]);

	const formRef = useRef(null);
	let handleSubmit = async (e) => {
		e.preventDefault();

		if (privacy === true) {
			if (password === confirmation) {
				const data = {
					username: username,
					name: name,
					surname: surname,
					date: date,
					email: email,
					password: password,
					base64: image64,
					newsletter: newsLetter,
				};
				try {
					await api.post(`${BASE_URL}/auth/signup`, data);
					dispatchModalState(changeModalState("success"));
				} catch (error) {
					console.log(error);
					dispatchModalState(changeModalState("error"));
				}
			} else {
				dispatchModalState(changeModalState("fail"));
				setPassword("");
				setConfirmation("");
			}
		} else {
			dispatchModalState(changeModalState("privacy"));
		}
	};

	let handleImageUpload = (e) => {
		const image = e.target.files[0];
		setImageUrl(URL.createObjectURL(image));
		const dimension = image.size / 1000000;
		if (dimension > 8) {
			CustomAlert({
				title: "Attenzione!",
				body: "File troppo grande, dimensione massima 8MB",
				cancelButton: "Ok",
				type: "error",
			});
			return;
		}
		const reader = new FileReader();
		reader.readAsDataURL(image);
		reader.onload = () => {
			const base64 = reader.result;
			setImage64(base64);
		};
		reader.onerror = (error) => {
			console.log("Error: ", error);
		};
	};

	let imageNotUploaded = (
		<>
			<input
				id='inputImages'
				name='image'
				type='file'
				accept='.png'
				onChange={handleImageUpload}
				placeholder='dsadsa'
			/>
			<label htmlFor='inputImages'>
				<img
					src={plusButton}
					style={{
						padding: 30,
						border: "30px solid #f29da5",
						borderRadius: 150,
						cursor: "pointer",
					}}
				/>
			</label>
		</>
	);

	let imageUploaded = (
		<div className='col1'>
			<input
				id='inputImages'
				name='image'
				type='file'
				accept='.png'
				onChange={handleImageUpload}
			></input>
			<label
				htmlFor='inputImages'
				className='flexCol1'
			>
				<img
					src={image64}
					style={{
						width: 250,
						height: 250,
						border: "30px solid #f29da5",
						borderRadius: 150,
						cursor: "pointer",
					}}
				/>
			</label>
		</div>
	);

	return (
		<FormControl
			ref={formRef}
			onSubmit={handleSubmit}
			style={{ width: "100%" }}
			sx={{
				"&.MuiFormControl-root:has(.Mui-error)": {
					// if a child of FormControl has class Mui-error apply css to FormControl
					borderLeft: "3px solid red",
				},
			}}
		>
			<Grid
				container
				style={{
					padding: 50,
					display: "flex",
					flexDirection: "row",
					height: "90vh",
				}}
			>
				<Grid
					xs={2}
					style={{
						height: "100%",
						width: "100%",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					{" "}
					{image64 === "" ? imageNotUploaded : imageUploaded}{" "}
				</Grid>
				<Grid
					xs={5}
					style={{
						height: "100%",
						width: "100%",
						display: "flex",
						flexDirection: "column",
						marginLeft: 50,
					}}
				>
					<span style={{ fontSize: 30, color: "#f29ca4", fontWeight: "bold" }}>
						Username
					</span>
					<TextField
						sx={{ border: "none", "& fieldset": { border: "none" } }}
						required
						placeholder='Username*'
						id='outlined'
						value={username}
						onChange={(e) => setUsername(e.target.value)}
						style={{
							backgroundColor: "#f4e9eb",
							borderRadius: "100px",
							border: "0px !important",
							height: "50px",
							minWidth: "40%",
							width: "20%",
						}}
					/>
					<div
						style={{
							width: "100%",
							height: "90%",
							display: "flex",
							flexDirection: "column",
							marginTop: 60,
						}}
					>
						<TextField
							required
							id='outlined'
							sx={{ border: "none", "& fieldset": { border: "none" } }}
							value={name}
							onChange={(e) => setName(e.target.value)}
							style={{
								backgroundColor: "#f4e9eb",
								borderRadius: "100px",
								border: "0px !important",
								height: "50px",
								minWidth: "60%",
								width: "20%",
								marginBottom: 20,
							}}
							placeholder='Nome*'
						/>
						<TextField
							required
							sx={{ border: "none", "& fieldset": { border: "none" } }}
							id='outlined'
							value={surname}
							onChange={(e) => setSurname(e.target.value)}
							style={{
								backgroundColor: "#f4e9eb",
								borderRadius: "100px",
								border: "0px !important",
								height: "50px",
								minWidth: "60%",
								width: "20%",
								marginBottom: 20,
							}}
							placeholder='Cognome*'
						/>
						<TextField
							required
							id='outlined'
							value={date}
							datatype='date'
							label=''
							sx={{ border: "none", "& fieldset": { border: "none" } }}
							onChange={(e) => setDate(e.target.value)}
							style={{
								backgroundColor: "#f4e9eb",
								borderRadius: "100px",
								border: "0px !important",
								height: "50px",
								minWidth: "60%",
								width: "20%",
								marginBottom: 20,
							}}
							placeholder='Data di nascita*'
							type='date'
						/>
						<TextField
							required
							id='outlined'
							value={email}
							sx={{ border: "none", "& fieldset": { border: "none" } }}
							onChange={(e) => setEmail(e.target.value)}
							style={{
								backgroundColor: "#f4e9eb",
								borderRadius: "100px",
								border: "0px !important",
								height: "50px",
								minWidth: "60%",
								width: "20%",
								marginBottom: 20,
							}}
							placeholder='Email*'
						/>
						<OutlinedInput
							id='outlined-adornment-pa22ssword'
							value={password}
							sx={{ border: "none", "& fieldset": { border: "none" } }}
							onChange={(e) => setPassword(e.target.value)}
							style={{
								backgroundColor: "#f4e9eb",
								borderRadius: "100px",
								border: "0px !important",
								height: "50px",
								minWidth: "60%",
								width: "20%",
								marginBottom: 20,
							}}
							placeholder='Password*'
							type={isEyePasswordShown ? "text" : "password"}
							endAdornment={
								<InputAdornment position='end'>
									<IconButton
										aria-label='toggle password visibi22lity'
										onClick={() => setEyePassowordShown(!isEyePasswordShown)}
										onMouseDown={() =>
											setEyePassowordShown(!isEyePasswordShown)
										}
										edge='end'
									>
										{isEyePasswordShown ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							}
							label='Password'
						/>
						<OutlinedInput
							error
							id='outlined-adornment-password2'
							sx={{ border: "none", "& fieldset": { border: "none" } }}
							value={confirmation}
							onChange={(e) => setConfirmation(e.target.value)}
							type={isEyePasswordShownConfirm ? "text" : "password"}
							style={{
								backgroundColor: "#f4e9eb",
								borderRadius: "100px",
								border: "0px !important",
								height: "50px",
								minWidth: "60%",
								width: "20%",
								marginBottom: 20,
							}}
							placeholder='Conferma password*'
							endAdornment={
								<InputAdornment position='end'>
									<IconButton
										aria-label='toggle password visibility2'
										onClick={() =>
											setEyePassowordShownConfirm(!isEyePasswordShownConfirm)
										}
										onMouseDown={() =>
											setEyePassowordShownConfirm(!isEyePasswordShownConfirm)
										}
										edge='end'
									>
										{isEyePasswordShownConfirm ? (
											<VisibilityOff />
										) : (
											<Visibility />
										)}
									</IconButton>
								</InputAdornment>
							}
							label='Conferma password*'
						/>
						<span style={{ marginTop: 10 }}>
							I campi contrassegnati con * sono obbligatori
						</span>
					</div>
				</Grid>
				<Grid
					xs={4}
					style={{
						width: "100%",
						height: "100%",
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-start",
						alignItems: "flex-start",
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignContent: "center",
							height: "100%",
						}}
					>
						<div
							style={{ display: "flex", flexDirection: "row", width: "100%" }}
						>
							<label
								className='checkbox-label'
								type='text'
								htmlFor='checkbox'
							>
								Accetto i{" "}
								<Link
									className='linkPrivacy'
									to={"/register/privacy"}
								>
									termini e le condizioni
								</Link>{" "}
								per la privacy{" "}
							</label>
							<Checkbox
								checked={privacy}
								style={{ color: "#f39ea6" }}
								onChange={(e) => setPrivacy(e.target.checked)}
							/>
						</div>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								width: "100%",
								justifyContent: "space-between",
							}}
						>
							<label
								className='checkbox-label'
								type='text'
								htmlFor='checkbox'
							>
								Voglio ricevere gli aggiornamenti<br></br>tramite e-mail{" "}
							</label>
							<Checkbox
								style={{ color: "#f39ea6" }}
								checked={newsLetter}
								onChange={(e) => setNewsLetter(e.target.checked)}
							/>
						</div>
						<Button
							variant='outlined'
							style={{
								border: "5px solid #f39ea6",
								color: "#000",
								fontSize: "25px",
								borderRadius: "28px",
								textTransform: "none",
								padding: "0px 23px 0px 24px",
								fontWeight: 600,
								height: "auto",
							}}
							onSubmit={handleSubmit}
							onClick={handleSubmit}
						>
							Registrati!
						</Button>{" "}
					</div>
				</Grid>
			</Grid>
		</FormControl>
	);
}

export default SignUpMenu;
