import React from "react";
import { useState } from "react";
import Header from "./Header";
import NavMenu from "./NavMenu";
import Loading from "./Loading";
import Modal from "./Modal";
import "../css/IlTuoSpazio.css";
import "../css/WriteSaveDraft.css";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changeLoadingState } from "../features/loadingState";

function WriteSaveDraft(props) {
	// IMPORTARE ANCHE L'ID DELL'USER PER EFFETTUARE IL SALVATAGGIO DELLE BOZZE / CAPITOLI
	// MANCANO APIs DI SALVATAGGIO, OCCORRE IMPLEMENTARLE NEL TASTO SALVA....!!!

	const isLoggedIn = localStorage.getItem("isLoggedIn");

	const [isDraftSaved] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState("saved");

	const isLoadingGlobal = useSelector((state) => state.loadingState.value);
	const dispatchLoadingState = useDispatch();
	const navigate = useNavigate();

	const [isLoadingLocal, setIsLoadingLocal] = useState(isLoadingGlobal);
	setTimeout(() => {
		setIsLoadingLocal(false);
	}, 1900);

	let handleClickRedirecting = (e) => {
		if (!isDraftSaved) {
			navigate("/writer/savedraft");
			setIsModalOpen("unsaved");
		}
		dispatchLoadingState(changeLoadingState(true));
	};

	let handleSubmit = (e) => {
		console.log(e);
	};

	let writeSaveDraft = (
		<div>
			<Header
				title='SALVA COME BOZZA'
				to='/home'
			/>
			<div className='baseWrapper'>
				<NavMenu onClick={(e) => handleClickRedirecting(e)} />
				<div className='wrapperDraftSave'>
					<div className='writeSaveDraftTitle'>
						Troverai la tua bozza nelle sezione: <br />
						<strong>"Il tuo spazio" - "Bozze"</strong>
					</div>
					<form
						className='saveDraftForm'
						onSubmit={handleSubmit}
					>
						<div className='titleSaveDraft'>
							<strong>Nome della bozza</strong>
						</div>
						<input
							className='inputSaveDraft'
							type='text'
						></input>
						<button
							className='buttonSaveDraft'
							type='submit'
						>
							Salva
						</button>
					</form>
				</div>
			</div>
			{isModalOpen === "unsaved" ? (
				<Modal
					style={{ top: "0", zIndex: "1" }}
					title='Bozza non salvata!'
					body="E' necessario salvare la bozza, se vuoi eliminarla potrai farlo in un secondo momento."
					buttonText='Indietro'
					onClose={() => {
						navigate("/writer/savedraft");
						setIsModalOpen("");
					}}
					onClick={() => {
						navigate("/writer/savedraft");
						setIsModalOpen("");
					}}
				/>
			) : (
				""
			)}
			{isModalOpen === "saved" ? (
				<Modal
					style={{ top: "0", zIndex: "1" }}
					title='Ottimo!'
					body='La tua bozza è stata salvata con successo. Potrai trovarla nel tuo spazio in bozze.'
					buttonText='Vai al tuo spazio!'
					onClose={() => {
						navigate("/write");
						setIsModalOpen("");
					}}
					onClick={() => {
						navigate("/write");
						setIsModalOpen("");
					}}
				/>
			) : (
				""
			)}
		</div>
	);

	return writeSaveDraft;
}

export default WriteSaveDraft;
