import React, { useEffect } from "react";
import { useState } from "react";
import Header from "./Header";
import Loading from "./Loading";
import "../css/NewBook.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BASE_URL from "./Constants";
import plusButton from "../img/inputImages/plusButton.png";
import "../css/Dropdown.css";
import api from "../api";
import CustomAlert from "./Alert";
function NewBook() {
	const isLoadingGlobal = useSelector((state) => state.loadingState.value);
	const navigate = useNavigate();
	const cod_user = localStorage.getItem("id");

	let [newInookData, setNewInookData] = useState({
		cod_user: cod_user,
		title: "",
		description: "",
		category: "",
		imgPath: "default",
		enabled: 0,
	});

	const [isLoadingLocal, setIsLoadingLocal] = useState(isLoadingGlobal);
	setTimeout(() => {
		setIsLoadingLocal(false);
	}, 1900);

	let handleChange = (e) => {
		const { name, value } = e.target;

		setNewInookData((prevState) => {
			return {
				...prevState,
				[name]: value,
			};
		});
	};

	let handleFormSubmit = async (e) => {
		e.preventDefault();
		const data = {
			title: newInookData.title,
			description: newInookData.description,
			cod_category: selectedValue.value,
			cod_user: cod_user,
			enabled: newInookData.enabled,
			imgPath: image64,
		};

		try {
			let token = localStorage.getItem("token");
			console.log(token);
			let resultInookCreation = await api.post(`${BASE_URL}/book/create`, data);
			setIsLoadingLocal(true);
			console.log(resultInookCreation.data);
			if (resultInookCreation.data.message === "Book created") {
				try {
					let userInookList = await api.get(
						`${BASE_URL}/book/getAllByUser/${cod_user}`
					);

					let extractedList = [{ ...userInookList.data.data }];
					let lastInookCreatedId =
						extractedList[0][Object.keys(extractedList[0]).length - 1].id;
					try {
						let newChapterData = {
							title: "Titolo capitolo",
							description: "Descrizione capitolo",
							number: "1",
							enabled: 0,
							cod_book: resultInookCreation.data.id,
						};

						await api.post(`${BASE_URL}/chapter/create`, newChapterData);
						let FirstChapterId = await api.get(
							`${BASE_URL}/chapter/getByBookId/${lastInookCreatedId}`
						);
						console.log(FirstChapterId.data);
						navigate(
							`/writer/${lastInookCreatedId}/${FirstChapterId.data.data[0].id}`
						);
					} catch (error) {
						console.log(error);
						setIsLoadingLocal(false);
					}
				} catch (error) {
					console.log(error);
					setIsLoadingLocal(false);
				}
			}
		} catch (error) {
			console.log(error);
			setIsLoadingLocal(false);
		}
	};

	const [showMenu, setShowMenu] = useState("");
	const [selectedValue, setSelectedValue] = useState(null);

	const handleInputClick = (e) => {
		e.stopPropagation();
		if (showMenu === "") {
			setShowMenu("showMenu");
		} else {
			setShowMenu("");
		}
	};

	const getDisplay = () => {
		if (selectedValue) {
			return selectedValue.label;
		}

		const placeHolder = "Seleziona un genere";
		return placeHolder;
	};

	const onItemClick = (option) => {
		setSelectedValue(option);
	};

	const isSelected = (option) => {
		if (!selectedValue) {
			return false;
		}

		return selectedValue.value === option.value;
	};

	const Icon = () => {
		return (
			<svg
				height='20'
				width='20'
				viewBox='0 0 20 20'
			>
				<path d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'></path>
			</svg>
		);
	};

	const [category, setCategory] = useState([]);
	const [file, setFile] = useState();
	const [image64, setImage64] = useState();
	function uploadFile(e) {
		const image = e.target.files[0];
		const dimension = image.size / 1000000;
		if (dimension > 8) {
			CustomAlert({
				title: "Attenzione!",
				body: "File troppo grande, dimensione massima 8MB",
				cancelButton: "Ok",
				type: "error",
			});
			return;
		}
		setFile(URL.createObjectURL(image));
		const reader = new FileReader();
		reader.readAsDataURL(image);
		reader.onload = () => {
			const base64 = reader.result;
			setImage64(base64);
		};
		reader.onerror = (error) => {
			console.log("Error: ", error);
		};
	}

	useEffect(() => {
		api
			.get(`${BASE_URL}/category/getAll`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			})
			.then((res) => {
				const categories = res.data?.data?.map((item) => ({
					value: item.id,
					label: item.title,
				}));
				setCategory(categories);
			});
	}, []);

	const dropdown = (
		<div
			onClick={handleInputClick}
			className='dropdown-container'
		>
			<div className='dropdown-input'>
				<div className='dropdown-selected-value'>{getDisplay()}</div>
				<div className='dropdown-tools'>
					<div className='dropdown-tool'>
						<Icon />
					</div>
				</div>
				<div className={`dropdown-menu ${showMenu}`}>
					{category.map((option) => (
						<label
							key={option.value}
							htmlFor='categoryInput'
							className={`dropdown-item ${isSelected(option) && "selected"}`}
							onClick={() => onItemClick(option)}
						>
							{option.label}
						</label>
					))}
				</div>
			</div>
			<input
				id='categoryInput'
				name='categoryInput'
				type='hidden'
				value={selectedValue !== null ? selectedValue.value : ""}
			></input>
		</div>
	);

	let newbookTest = (
		<div>
			<Header
				title='SCRIVI UN NUOVO INOOK'
				to='/home'
			/>
			<div className='newBookWrapper'>
				<div className='newBookFirstCol'>
					<input
						id='inputImages'
						name='image'
						type='file'
						accept='.png'
						onChange={uploadFile}
					></input>
					<label
						htmlFor='inputImages'
						className='flexColNewBook'
					>
						{file ? (
							<img
								src={file}
								className='newBookFirstCol'
								style={{ width: "100%", height: "100%" }}
								alt='coverImage'
							/>
						) : (
							<img
								src={plusButton}
								alt='plusButton'
								className='plusButtonNewBook'
							/>
						)}
					</label>
				</div>
				<div className='newBookSecondCol'>
					<p className='newBookParagraph'>
						<strong>
							Hai scelto di scrivere un nuovo inook! <br></br>
							Inserisci qui i dati richiesti. <br></br>
							Tranquillo: potrai modificarli più avanti!
						</strong>
					</p>
					<form
						onSubmit={handleFormSubmit}
						className='newBookForm'
					>
						<input
							type='text'
							name='title'
							className='newBookTitle'
							placeholder='Titolo provvisorio'
							onChange={(e) => handleChange(e)}
						/>
						<textarea
							type='text'
							name='description'
							className='newBookDescription'
							placeholder='Descrizione provvisoria'
							onChange={(e) => handleChange(e)}
						/>
						<div className='newBookSubmissionWrapper'>
							{dropdown}
							<button
								type='submit'
								className='newBookSubmit'
								onClick={handleFormSubmit}
								disabled={!isLoadingGlobal}
							>
								{" "}
								Scrivi{" "}
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);

	return <div>{isLoadingLocal ? <Loading /> : newbookTest}</div>;
}

export default NewBook;
