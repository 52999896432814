import React, { useState, useEffect } from "react";
import "../css/ModProfileMenu.css";
import SignUpInputReadOnly from "./SignUpInputReadOnly";
import BASE_URL from "./Constants";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeModalState } from "../features/modalState";
import circlePlusButton from "../img/inputImages/circlePlusButton.png";
import plusButton from "../img/inputImages/plusButton.png";
import { IconButton, InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import api from "../api";
import CustomAlert from "./Alert";

function ModProfileMenu() {
	const userCode = localStorage.getItem("id");
	const [user, setUser] = useState({});

	const [imageUrl, setImageUrl] = useState("");
	const [image64, setImage64] = useState("");
	const [showPasswordNew, setShowPasswordNew] = useState(false);
	const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
	const [username, setUsername] = useState(user?.username);
	const [name, setName] = useState(user?.name);
	const [surname, setSurname] = useState(user?.surname);
	const [date, setDate] = useState(user?.data);
	const [email, setEmail] = useState(user?.email);
	const [password, setPassword] = useState("");
	const [confirmation, setConfirmation] = useState("");
	const [showPassword, setShowPassword] = useState(false);

	useEffect(() => {
		api
			.get(`${BASE_URL}/profile/get`)
			.then((res) => {
				setUser(res.data?.data);
				setUsername(res.data?.data.username);
				setName(res.data?.data.name);
				setSurname(res.data?.data.surname);
				setDate(res.data?.data.data);
				setEmail(res.data?.data.email);
				setImageUrl(res.data?.data.imgPath);
				setDate(res.data?.data.date);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const navigate = useNavigate();

	const [oldPassword, setOldPassword] = useState("");

	const dispatchModalState = useDispatch();

	const handleEyeClick = () => {
		setShowPassword(!showPassword);
	};
	const handleEyeClickNew = () => {
		setShowPasswordNew(!showPasswordNew);
	};
	const handleEyeClickConfirm = () => {
		setShowPasswordConfirm(!showPasswordConfirm);
	};

	let handleSubmit = async (e) => {
		e.preventDefault();

		try {
			const dataCheck = {
				username: username,
				password: oldPassword,
			};

			let passwordCheck = await api.post(`${BASE_URL}/auth/login`, dataCheck);

			if (passwordCheck.data.message === "Success") {
				if (password === confirmation) {
					const data = {
						cod_user: userCode,
						password: oldPassword,
						newPassword: password,
						imgPath: image64,
					};

					try {
						await api.post(`${BASE_URL}/profile/update`, data);
						dispatchModalState(changeModalState("success"));
					} catch (error) {
						console.log(error);
						dispatchModalState(changeModalState("error"));
					}
				} else {
					dispatchModalState(changeModalState("fail"));
					setPassword("");
					setConfirmation("");
				}
			}
		} catch (error) {
			setOldPassword("");
			setPassword("");
			setConfirmation("");
			dispatchModalState(changeModalState("password"));
		}
	};

	let handleSubmitProfile = async (e) => {
		e.preventDefault();

		const data = {
			imgPath: image64,
		};

		try {
			await api.post(`${BASE_URL}/profile/update`, data);
			dispatchModalState(changeModalState("success"));
		} catch (error) {
			console.log(error);
			dispatchModalState(changeModalState("error"));
		}
	};

	let handleImageUpload = (e) => {
		const image = e.target.files[0];
		const dimension = image.size / 1000000;
		if (dimension > 8) {
			CustomAlert({
				title: "Attenzione!",
				body: "File troppo grande, dimensione massima 8MB",
				cancelButton: "Ok",
				type: "error",
			});
			return;
		}
		const reader = new FileReader();
		setImageUrl(URL.createObjectURL(image));

		reader.readAsDataURL(image);
		reader.onload = () => {
			const base64 = reader.result;
			setImage64(base64);
		};
	};

	let imageNotUploaded = (
		<div className='col1'>
			{/* AGGIUNGERE IMMAGINE / BUTTON CARICAMENTO FOTO PROFILO */}
			<input
				id='inputImages'
				name='image'
				type='file'
				accept='.png'
				onChange={handleImageUpload}
			></input>
			<label
				htmlFor='inputImages'
				className='flexCol1'
			>
				<img
					src={circlePlusButton}
					className='circlePlusButton'
					alt='circle'
				/>
				<img
					src={plusButton}
					className='plusButton'
					alt='plus'
				/>
			</label>
		</div>
	);

	let imageUploaded = (
		<div className='col1'>
			<input
				id='inputImages'
				name='image'
				type='file'
				accept='.png'
				onChange={handleImageUpload}
			></input>
			<label
				htmlFor='inputImages'
				className='flexCol1'
			>
				<div
					className='imageUploadedProfile'
					style={{
						backgroundImage: `url(${imageUrl})`,
						cursor: "pointer",
						objectFit: "cover",
						backgroundSize: "cover",
						backgroundPosition: "center",
					}}
				></div>
			</label>
		</div>
	);

	return (
		<form onSubmit={!oldPassword ? handleSubmitProfile : handleSubmit}>
			<div className='containerProfile'>
				{imageUrl === "" ? imageNotUploaded : imageUploaded}
				<div className='col2Profile'>
					<label
						id='big-inputProfile'
						htmlFor='big-inputProfile'
					>
						Username
					</label>
					<SignUpInputReadOnly
						id='big-inputProfile'
						className='BIG SignUpInput-containerProfile'
						name='username'
						type='text'
						onChange={(e) => setUsername(e.target.value)}
						value={username || ""}
					/>
					<SignUpInputReadOnly
						className='first SignUpInput-containerProfile'
						name='name'
						placeholder='Nome'
						type='text'
						onChange={(e) => setName(e.target.value)}
						value={name || ""}
					/>
					<SignUpInputReadOnly
						className='SignUpInput-containerProfile'
						name='surname'
						placeholder='Cognome'
						type='text'
						onChange={(e) => setSurname(e.target.value)}
						value={surname || ""}
					/>
					<SignUpInputReadOnly
						className='SignUpInput-containerProfile'
						name='birthdate'
						placeholder='Data di nascita'
						type=''
						onChange={(e) => setDate(e.target.value)}
						value={date || ""}
					/>
					<SignUpInputReadOnly
						className='SignUpInput-containerProfile'
						name='email'
						placeholder='E-mail'
						type='email'
						onChange={(e) => setEmail(e.target.value)}
						value={email || ""}
					/>

					<OutlinedInput
						id='outlined-adornment-password'
						value={oldPassword}
						fullWidth
						style={{
							backgroundColor: "#f4e9eb",
							border: "0px !important",
							borderRadius: "40px",
							height: "5vh",
							width: "90%",
							marginTop: "1vh",
						}}
						sx={{ border: "none", "& fieldset": { border: "none" } }}
						onChange={(e) => setOldPassword(e.target.value)}
						type={showPassword ? "text" : "password"}
						endAdornment={
							<InputAdornment position='end'>
								<IconButton
									aria-label='toggle password visibility'
									onClick={handleEyeClick}
									/* onMouseDown={handleEyeClick} */
									edge='end'
								>
									{showPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						}
						placeholder='Password attuale'
						label='Password'
					/>
					<OutlinedInput
						id='outlined-adornment-password'
						value={password}
						fullWidth
						style={{
							backgroundColor: "#f4e9eb",
							border: "0px !important",
							borderRadius: "40px",
							height: "5vh",
							width: "90%",
							marginTop: "1vh",
						}}
						sx={{ border: "none", "& fieldset": { border: "none" } }}
						onChange={(e) => setPassword(e.target.value)}
						type={showPasswordNew ? "text" : "password"}
						endAdornment={
							<InputAdornment position='end'>
								<IconButton
									aria-label='toggle password visibility'
									onClick={handleEyeClickNew}
									edge='end'
								>
									{showPasswordNew ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						}
						placeholder='Nuova password'
						label='Password'
					/>
					<OutlinedInput
						id='outlined-adornment-password'
						value={confirmation}
						fullWidth
						style={{
							backgroundColor: "#f4e9eb",
							border: "0px !important",
							borderRadius: "40px",
							height: "5vh",
							width: "90%",
							marginTop: "1vh",
						}}
						sx={{ border: "none", "& fieldset": { border: "none" } }}
						onChange={(e) => setConfirmation(e.target.value)}
						type={showPasswordConfirm ? "text" : "password"}
						endAdornment={
							<InputAdornment position='end'>
								<IconButton
									aria-label='toggle password visibility'
									onClick={handleEyeClickConfirm}
									edge='end'
								>
									{showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						}
						label='Password'
						placeholder='Conferma Password'
					/>
				</div>
				<div className='col3Profile'>
					<button
						className='submit-borderProfile'
						type='submit'
					>
						Salva
					</button>
					<button
						className='submit-borderProfile'
						style={{ marginTop: "5vh" }}
						onClick={() => navigate("/profile")}
					>
						Annulla
					</button>
				</div>
			</div>
		</form>
	);
}

export default ModProfileMenu;
