import React, { useEffect } from "react";
import Header from "./Header";
import NavMenu from "./NavMenu";
import Button from "./Button";
import Modal from "./Modal";
import Loading from "./Loading";
import "../css/WriteContinue.css";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { changeLoadingState } from "../features/loadingState";
import BASE_URL from "./Constants";
import { Delete } from "@mui/icons-material";
import api from "../api";
import CustomAlert from "./Alert";
function WriteContinueChapter() {
	const userCode = localStorage.getItem("id");
	const isLoggedIn = localStorage.getItem("isLoggedIn");

	const isLoadingGlobal = useSelector((state) => state.loadingState.value);
	const dispatchLoadingState = useDispatch();
	const navigate = useNavigate();

	const [isLoadingLocal, setIsLoadingLocal] = useState(isLoadingGlobal);
	setTimeout(() => {
		setIsLoadingLocal(false);
	}, 1900);

	const bookId = useParams();
	const [enbledChaptersList, setEnabledChaptersList] = useState([]);
	const [fullChaptersList, setFullChaptersList] = useState([]);

	useEffect(() => {
		grabChapters();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let handleClickRedirecting = async (chapterId) => {
		if (!chapterId) {
			try {
				let newChapterNumber =
					fullChaptersList.length === 0
						? 1
						: Math.max(...fullChaptersList.map((chapter) => chapter.number)) +
						  1;
				console.log(newChapterNumber);

				let newChapterData = {
					title: "Titolo provvisorio",
					description: "Descrizione provvisoria",
					number: newChapterNumber || 1,
					enabled: 0,
					text: "Testo provvisorio",
					codUser: userCode,
					cod_book: bookId.id,
				};

				await api.post(`${BASE_URL}/chapter/create`, newChapterData);
				let newChapterResponse = await api.get(
					`${BASE_URL}/chapter/getByBookId/${bookId.id}`
				);

				let newChapterId = Math.max(
					...newChapterResponse.data.data.map((chapter) => chapter.id)
				);

				dispatchLoadingState(changeLoadingState(true));
				navigate(`/writer/${bookId.id}/${newChapterId}`);
			} catch (error) {
				console.log(error);
			}
		} else {
			dispatchLoadingState(changeLoadingState(true));
			navigate(`/writer/${bookId.id}/${chapterId}`);
		}
	};

	const changeEnabledChapter = async (enabled, chapterId) => {
		try {
			let res = await api.post(`${BASE_URL}/chapter/updateEnabled`, {
				id: chapterId,
				enabled,
			});
			grabChapters();
			if (enabled === 0)
				CustomAlert({
					title: "Congratulazioni!",
					body: "Il capitolo è stato spostato in bozze!",
					cancelButton: "Ok",
					type: "success",
				});
			else
				CustomAlert({
					title: "Congratulazioni!",
					body: "Il capitolo è stato pubblicato!",
					cancelButton: "Ok",
					type: "success",
				});
		} catch (error) {
			console.log(error);
		}
	};

	const handleDeleteChapter = async (chapterId) => {
		try {
			await api.delete(`${BASE_URL}/chapter/delete/${chapterId}`);
			grabChapters();
		} catch (error) {
			console.log(error);
		}
	};

	let grabChapters = async () => {
		try {
			let res = await api.get(`${BASE_URL}/chapter/getByBookId/${bookId.id}`);
			setEnabledChaptersList(res.data.data);
			setFullChaptersList(res.data.data);
		} catch (error) {
			console.log(error);
		}
	};

	let writeContinueChapter = (
		<div>
			<Header
				title='I MIEI INOOK'
				to='/home'
			/>
			<div className='baseWrapper'>
				<NavMenu />
				<div className='choicesWrapper'>
					{enbledChaptersList.map((inook) => (
						<div className='choiseWrite'>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "flex-start",
									width: "100%",
									fontSize: 25,
								}}
							>
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
										width: "100%",
										padding: "0px 10px",
									}}
								>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											justifyContent: "flex-start",
											alignItems: "flex-start",
										}}
									>
										<span
											onClick={() => handleClickRedirecting(inook.id)}
											style={{ cursor: "pointer", marginLeft: 10 }}
										>
											{inook.title}
										</span>
										<span
											onClick={() => handleDeleteChapter(inook.id)}
											style={{ cursor: "pointer" }}
										></span>
										{inook.enabled === 0 ? (
											<span
												style={{
													fontSize: 15,
													padding: "0 15px",
													marginTop: 0,
													marginBottom: 10,
													display: "flex",
													justifyContent: "flex-start",
												}}
											>
												<span style={{ marginRight: 5 }}>
													Capitolo non pubblicato{" "}
												</span>
												<span>{" | "}</span>
												<span
													style={{
														color: "#f19da6",
														cursor: "pointer",
														textDecoration: "underline",
														marginLeft: 5,
													}}
													onClick={() => changeEnabledChapter(1, inook.id)}
												>
													{" "}
													Vuoi pubblicarlo ora ?
												</span>
											</span>
										) : (
											<span
												style={{
													fontSize: 15,
													padding: "0 15px",
													marginTop: 0,
													marginBottom: 10,
													display: "flex",
													justifyContent: "flex-start",
												}}
											>
												<span style={{ marginRight: 5 }}>
													Capitolo pubblicato{" "}
												</span>
												<span>{" | "}</span>
												<span
													style={{
														color: "#f19da6",
														cursor: "pointer",
														textDecoration: "underline",
														marginLeft: 5,
													}}
													onClick={() => changeEnabledChapter(0, inook.id)}
												>
													{" "}
													Vuoi spostarlo in bozze?
												</span>
											</span>
										)}
									</div>
									<Delete
										color='#f29da5'
										htmlColor='#f29da5'
										style={{ height: 30, width: 30 }}
									/>
								</div>
							</div>
						</div>
					))}
					<Button
						href=''
						name='+ nuovo capitolo'
						className='choiseWrite'
						onClick={() => handleClickRedirecting()}
					/>
				</div>
			</div>
		</div>
	);

	return writeContinueChapter;
}

export default WriteContinueChapter;
