import React from "react";
import "../css/Button.css";
import { Link } from 'react-router-dom';


function Button(props){
    return(
        <div className={props.className} onClick={props.onClick}>
            <Link 
                className="link" 
                to={props.href}
            >
                {props.name}
            </Link>
        </div>
)}

export default Button;