import React, { useState } from "react";
import "../css/SendEmailResetPsw.css";
import { useNavigate } from "react-router-dom";
import api from "../api";
import BASE_URL from "./Constants";
import Modal from "./Modal";
import Loading from "./Loading";
import { useSelector, useDispatch } from "react-redux";
import { changeLoadingState } from "../features/loadingState";
import { addUserEmail } from "../features/userMail";

function SendEmailResetPsw() {
	const navigate = useNavigate();

	const [email, setEmail] = useState("");

	const isLoadingGlobal = useSelector((state) => state.loadingState.value);
	const dispatchLoadingState = useDispatch();
	const dispatchUserMail = useDispatch();

	const [isLoadingLocal, setIsLoadingLocal] = useState(isLoadingGlobal);
	setTimeout(() => {
		setIsLoadingLocal(false);
	}, 1900);

	const [isOpen, setIsOpen] = useState("");

	let handleSubmit = async (e) => {
		e.preventDefault();

		const data = { email: email };

		try {
			let res = await api.post(`${BASE_URL}/auth/reset`, data);

			if (res.status === 200) {
				setIsOpen("success");
			}
		} catch (error) {
			setIsOpen("error");
			console.log(error);
		}
	};

	function handleClickModal(message) {
		if (message === "success") {
			dispatchUserMail(addUserEmail(email));
			dispatchLoadingState(changeLoadingState(true));
			setIsOpen("");
			setEmail("");
			navigate("/");
		} else if (message === "error") {
			setIsOpen("");
			setEmail("");
		}
	}

	let SendEmailComponent = (
		<div className='main_Border'>
			<h3 className='reset_Paragraph'>
				<strong>
					Inserisci la tua e-mail.<br></br>
				</strong>

				<p className='reset_Paragraph_1'>
					A breve riceverai un'e-mail di conferma <br></br>
					con tutti i dettagli per completare il processo!
				</p>
			</h3>

			<form onSubmit={handleSubmit}>
				{/* EMAIL INPUT */}
				<div className='reset_mail_label'>
					<div
						className='reset_mail_label_text'
						name='username'
					>
						E-mail
					</div>

					<input
						className='reset_mail_input'
						value={email || ""}
						name='email'
						onChange={(e) => setEmail(e.target.value)}
						required
						type='email'
					/>
				</div>

				<div className='reset_Redirecting'>
					<button
						type='submit'
						className='reset_Button'
					>
						Invia e-mail
					</button>
				</div>
			</form>

			{isOpen === "success" ? (
				<Modal
					style={{ top: "0", right: "7px", zIndex: "1" }}
					title='Ottimo!'
					body='Nel giro di qualche minuto ti invieremo una mail con un codice per completare il processo'
					buttonText='Vai al login'
					onClose={() => handleClickModal("success")}
					onClick={() => handleClickModal("success")}
				/>
			) : (
				""
			)}

			{isOpen === "error" ? (
				<Modal
					style={{ top: "0", right: "7px", zIndex: "1" }}
					title='Ops..!'
					body="Qualcosa è andato storto, prova ad inserire un'e-mail valida."
					buttonText='Riprova'
					onClose={() => handleClickModal("error")}
					onClick={() => handleClickModal("error")}
				/>
			) : (
				""
			)}
		</div>
	);

	return <div>{isLoadingLocal ? <Loading /> : SendEmailComponent}</div>;
}

export default SendEmailResetPsw;
