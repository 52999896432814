import React from "react";
import "../css/NavMenu.css";

import Button from "./Button";

function NavMenu(props) {
	return (
		<div className='navMenu-container'>
			<Button
				name='Home'
				href='/home'
				className='button first-button'
				onClick={props.onClick}
			/>
			<Button
				href='/write'
				name='Il tuo spazio'
				className='button'
				onClick={props.onClick}
			/>
			<Button
				href='/wallet'
				name='Portafoglio'
				className='button'
				onClick={props.onClick}
			/>
			<Button
				href='/profile'
				name='Profilo'
				className='button last-button'
				onClick={props.onClick}
			/>
		</div>
	);
}

export default NavMenu;
