/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "../css/SendEmailResetPsw.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import BASE_URL from "./Constants";
import Modal from "./Modal";
import { useSelector, useDispatch } from "react-redux";
import { changeLoadingState } from "../features/loadingState";

function ValidateUser() {
	const navigate = useNavigate();
	const { code } = useParams();

	const isLoadingGlobal = useSelector((state) => state.loadingState.value);
	const dispatchLoadingState = useDispatch();

	const [_, setIsLoadingLocal] = useState(isLoadingGlobal);
	setTimeout(() => {
		setIsLoadingLocal(false);
	}, 1900);

	const [response, setResponse] = useState("");

	const [isOpen, setIsOpen] = useState("");

	let handleSubmit = async () => {
		console.log(code);
		const data = { code };

		try {
			let res = await axios.post(`${BASE_URL}/auth/confirmUser`, data);

			if (res.status === 200) {
				setResponse(res.status);
				console.log();
				setIsOpen("");
				dispatchLoadingState(changeLoadingState(true));
				navigate("/confirmed");
			}
		} catch (error) {
			setIsOpen("error");
			navigate("/denied");
			alert(error);
		}
	};

	function handleClickModal(message) {
		if (message === "success") {
		} else if (message === "error") {
			setIsOpen("");
		}
	}

	let ValidateUserComponent = (
		<div className='main_Border'>
			<h3 className='reset_Paragraph'>
				<strong>
					Abbiamo quasi terminato!!<br></br>
				</strong>

				<p className='reset_Paragraph_1'>
					Inserisci il codice che abbiamo spedito <br></br>
					all'indirizzo e-mail indicato durante la registrazione.
				</p>
			</h3>

			<form onSubmit={handleSubmit}>
				{/* EMAIL INPUT */}
				<div className='reset_mail_label'>
					<div
						className='reset_mail_label_text'
						name='username'
					>
						Codice
					</div>

					<input
						className='reset_mail_input'
						value={code || ""}
						name='code'
						required
						type='text'
					/>
				</div>

				<div className='reset_Redirecting'>
					<button
						type='submit'
						className='reset_Button'
					>
						Invia codice
					</button>
				</div>
			</form>

			{isOpen === "error" ? (
				<Modal
					style={{ top: "0", right: "7px", zIndex: "1" }}
					title='Ops..!'
					body='Qualcosa è andato storto, prova a reinserire il codice ...'
					buttonText='Riprova'
					onClose={() => handleClickModal("error")}
					onClick={() => handleClickModal("error")}
				/>
			) : (
				""
			)}
		</div>
	);

	handleSubmit();

	return (
		<div>
			{/* {isLoadingLocal ? <Loading /> 
            : response === 200 ? <RegConfirmed />
            : <RegDenied />} */}
		</div>
	);
}

export default ValidateUser;
