import React, { useEffect } from "react";
import Header from "./Header";
import NavMenu from "./NavMenu";
import Button from "./Button";
import Modal from "./Modal";
import Loading from "./Loading";
import "../css/WriteContinue.css";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { changeLoadingState } from "../features/loadingState";
import BASE_URL from "./Constants";
import api from "../api";

function WriteDraftChapter() {
	const isLoggedIn = localStorage.getItem("isLoggedIn");

	const isLoadingGlobal = useSelector((state) => state.loadingState.value);
	const dispatchLoadingState = useDispatch();
	const navigate = useNavigate();

	const [isLoadingLocal, setIsLoadingLocal] = useState(isLoadingGlobal);
	setTimeout(() => {
		setIsLoadingLocal(false);
	}, 1900);

	const bookId = useParams();
	const [draftsList, setDraftsList] = useState([]);

	useEffect(() => {
		grabChapters();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let handleClickRedirecting = (chapterId) => {
		dispatchLoadingState(changeLoadingState(true));
		navigate(`/writer/${bookId.id}/${chapterId}`);
	};

	let grabChapters = async () => {
		try {
			let res = await api.get(`${BASE_URL}/chapter/getByBookId/${bookId.id}`);
			setDraftsList(res.data.data.filter((inook) => inook.enabled === 0));
		} catch (error) {
			console.log(error);
		}
	};

	let writeDraft = (
		<div>
			<Header
				title='I MIEI INOOK'
				to='/home'
			/>
			<div className='baseWrapper'>
				<NavMenu />
				<div className='choicesWrapper'>
					{draftsList.length === 0 ? (
						<div className='choiseNotExist'>
							Non esiste ancora alcuna bozza creata nei nostri sistemi!<br></br>
							Torna indietro per iniziare a creare!
						</div>
					) : (
						draftsList.map((inook) => (
							<Button
								key={inook.id}
								href=''
								name={inook.title}
								className='choiseWrite'
								onClick={() => handleClickRedirecting(inook.id)}
							/>
						))
					)}
				</div>
			</div>
		</div>
	);

	return writeDraft;
}

export default WriteDraftChapter;
