import React from "react";
import { useState } from "react";
import Header from "./Header";
import NavMenu from "./NavMenu";
import Loading from "./Loading";
import Button from "./Button";
import "../css/IlTuoSpazio.css";
import { useSelector, useDispatch } from "react-redux";
import { changeLoadingState } from "../features/loadingState";

function IlTuoSpazio() {
	const isLoadingGlobal = useSelector((state) => state.loadingState.value);
	const dispatchLoadingState = useDispatch();

	const [isLoadingLocal, setIsLoadingLocal] = useState(isLoadingGlobal);
	setTimeout(() => {
		setIsLoadingLocal(false);
	}, 1900);

	let handleClickRedirecting = () => {
		dispatchLoadingState(changeLoadingState(true));
	};

	let yourSpace = (
		<div>
			<Header
				title='IL TUO SPAZIO'
				to='/home'
			/>
			<div className='baseWrapper'>
				<NavMenu onClick={() => handleClickRedirecting()} />
				<div className='choicesWrapperYourSpace'>
					<Button
						name='Scrivi un nuovo inook'
						href='/write/newInook'
						className='buttonWriteRedirecting first-button'
						onClick={() => handleClickRedirecting()}
					/>

					<Button
						href='/write/continueInook'
						name='Continua un inook'
						className='buttonWriteRedirecting'
						onClick={() => handleClickRedirecting()}
					/>
				</div>
			</div>
		</div>
	);

	return <div>{isLoadingLocal ? <Loading /> : yourSpace}</div>;
}

export default IlTuoSpazio;
