import React, { useState, useEffect, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import "../css/Writer.css";
import Modal from "./Modal";
import Loading from "./Loading";
import BASE_URL from "./Constants";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import "../css/Writer.css";
import { IconButton } from "@mui/material";
import { useSelector } from "react-redux";
import { Add } from "@mui/icons-material";
import api from "../api";
import CustomAlert from "./Alert";

const Writer = () => {
	const isLoggedIn = localStorage.getItem("isLoggedIn");
	const navigate = useNavigate();
	const [enabledButton, setEnabledButton] = useState(false);
	//const alert = useAlert();
	let { chapterId } = useParams();
	const [dataStateSaved, setDataStateSaved] = useState("unsaved");
	const [isModalOpen, setIsModalOpen] = useState("");

	const isLoadingGlobal = useSelector((state) => state.loadingState.value);
	const [isLoadingLocal, setIsLoadingLocal] = useState(isLoadingGlobal);

	setTimeout(() => {
		setIsLoadingLocal(false);
	}, 1900);

	const [chapterData, setChapterData] = useState([
		{
			id: 0,
			enabled: 0,
			title: "",
			description: "",
			number: 0,
			text: "",
			booktitle: "",
			pages: [],
		},
	]);

	useEffect(() => {
		grabChapterData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let grabChapterData = async () => {
		try {
			let pages = await api.get(`${BASE_URL}/chapter/getById/${chapterId}`);
			setChapterData(pages.data.data?.[0]);
			setEnabledButton(true);
			setDataStateSaved("saved");
			console.log(
				pages.data.pages?.map((page) => page.text),
				"console log"
			);
			const pagesFiltered = pages.data.pages?.map((page) => page.text) || [""];
			console.log(pagesFiltered, "console log");
			setTextPage(pagesFiltered?.length > 0 ? pagesFiltered : [""]);
		} catch (error) {
			console.log(error);
		}
	};

	let handleChapterNameChange = (e) => {
		const { name, value } = e.target;
		setEnabledButton(false);
		setDataStateSaved("unsaved");

		setChapterData((prevState) => {
			return {
				...prevState,
				[name]: value,
			};
		});
	};

	const [isConfirmSaved, setIsConfirmSaved] = useState({
		modal: false,
		text: "",
	});

	const handleSaveForm = async (e) => {
		e.preventDefault();

		let data = {
			id: chapterData.id,
			title: chapterData.title,
			description: chapterData.description,
			number: chapterData.number,
			enabled: chapterData.enabled,
			pages: textPage,
		};

		try {
			let res = await api.post(`${BASE_URL}/chapter/update`, data);
			if (res.status === 200) {
				/* 	alert.success("Capitolo salvato con successo!"); */
				setEnabledButton(true);
				setIsConfirmSaved({
					modal: true,
					text: "I dati relativi al capitolo sono stati salvati con successo! Troverai il capitolo nella sezione bozze",
				});
				setDataStateSaved("saved");
			}
		} catch (error) {
			console.log(error);
		}
	};

	function changeValue(e) {
		if (e === "<p><br></p>") return;
		let pageCurrent = textPage;
		pageCurrent[currentPage] = e;
		console.log(pageCurrent);
		setTextPage(pageCurrent);
		setEnabledButton(false);
		setDataStateSaved("unsaved");
	}
	let handleRedirecting = (e) => {
		let buttonClicked = e.target.name;

		switch (dataStateSaved) {
			// Se l'utente NON ha salvato avvertiamolo con un modale
			case "unsaved":
				setIsModalOpen("open");
				break;

			// Se l'utente ha salvato i dati allora puoi effettuare il redirecting
			case "saved":
				switch (buttonClicked) {
					case "home":
						navigate("/home");
						break;

					case "space":
						navigate("/write");
						break;

					default:
						console.log("No button detected... Try again");
						break;
				}
				break;

			default:
				console.log("No data state... Try again");
				break;
		}
	};

	const [currentPage, setCurrentPage] = useState(0);
	const [textPage, setTextPage] = useState([""]);

	const publishChapter = async () => {
		if (chapterData?.bookEnabled === 0) {
			window.location.replace(`/write/publishInook/${chapterData?.bookID}`);
			return;
		}

		try {
			let res = await api.post(`${BASE_URL}/chapter/publish`, {
				id: chapterId,
			});
			if (res.status === 200) {
				setIsConfirmSaved({
					modal: true,
					text: "I dati relativi al capitolo pubblicati con successo!",
				});
				setEnabledButton(true);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const DeleteCurrentPage = () => {
		/* if (textPage.length <= 1) {
			alert.error("Non puoi eliminare l'unica pagina del capitolo");
			return;
		} 	setEnabledButton(false);
		
	}; */

		if (textPage.length <= 1) {
			CustomAlert({
				title: "Attenzione!",
				body: "Non puoi eliminare l'unica pagina del capitolo",
				cancelButton: "Ok",
				type: "error",
			});
		} else
			CustomAlert({
				title: "Attenzione!",
				body: "Sei sicuro di voler eliminare la pagina?",
				cancelButton: "Annulla",
				okButton: "Conferma",
				handleClickDelete: () => {
					setDataStateSaved("unsaved");
					setEnabledButton(false);
					setTextPage(
						textPage.filter((_, index) => index !== currentPage) || ""
					);
					if (currentPage > 0) setCurrentPage(currentPage - 1);
					else setCurrentPage(0);
				},
			});
	};

	var icons = ReactQuill.Quill.import("ui/icons");
	icons["delete"] = '<i class="fa fa-trash"/>';

	const modules = () => ({
		toolbar: {
			container: [
				[{ font: [] }],
				[{ header: [1, 2, 3, 4, 5, 6, false] }],
				["bold", "italic", "underline", "strike"],
				[{ color: [] }, { background: [] }],
				[{ script: "sub" }, { script: "super" }],
				["blockquote", "code-block"],
				[{ list: "ordered" }, { list: "bullet" }],

				[{ indent: "-1" }, { indent: "+1" }, { align: [] }],
				[{ direction: "rtl" }],
				[{ size: ["small", false, "large", "huge"] }],
				["link"],
				["clean"],
			],

			history: {
				delay: 500,
				maxStack: 100,
				userOnly: true,
			},
		},
	});

	let writer = (
		<form className='WrapperWriter'>
			<div className='colWrapperWriter col1'>
				<input
					type='text'
					className='sizeWrapperWriter title'
					placeholder='Titolo del capitolo...'
					name='title'
					defaultValue={chapterData.title}
					maxLength={80}
					onChange={(e) => handleChapterNameChange(e)}
				/>
				<div className='sizeWrapperWriter desc'>
					<input
						type='text'
						className='descriptionWrapperWriter'
						placeholder='Descrizione...'
						name='description'
						maxLength={200}
						defaultValue={chapterData.description}
						onChange={(e) => handleChapterNameChange(e)}
					/>
					<div className='numberChapterWrapperWriter'>{chapterData.number}</div>
					<div className='hidedDescriptionWrapper'>Numero capitolo</div>
				</div>
				<div className='sizeWrapperWriter editor'>
					<div
						style={{
							display: "flex",
							alignContent: "flex-end",
							justifyContent: "flex-end",
						}}
					>
						<span
							style={{
								color: "#ff0000",
								fontSize: "16px",
								fontStyle: "italic",
								textDecoration: "underline",
								cursor: "pointer",
							}}
							onClick={() => DeleteCurrentPage()}
						>
							Elimina pagina
						</span>
					</div>
					<ReactQuill
						theme='snow'
						modules={useMemo(() => modules(), [])}
						style={{
							height: "60vh",
							border: "0px solid #000",
							overflowY: "hidden",
							overflowX: "hidden",
						}}
						scrollingContainer={".editor"}
						value={textPage[currentPage]}
						onChange={(e) => changeValue(e)}
					/>
				</div>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
						alignContent: "center",
						alignItems: "center",
						overflowX: "auto",
						overflowY: "scroll",
					}}
				>
					{textPage?.length > 0 &&
						textPage?.map((_, index) => {
							return (
								<span
									style={{
										color: currentPage === index ? "#f29ca4" : "#000",
										marginLeft: "10px",
										cursor: "pointer",
										padding: "5px 15px",
										border:
											currentPage === index
												? "1px solid #f29ca4"
												: "1px solid #000",
										borderRadius: currentPage === index ? "50px" : "50px",
									}}
									onClick={() => {
										setCurrentPage(index);
									}}
								>
									{index + 1}
								</span>
							);
						})}
					<IconButton
						color='#f29ca4'
						style={{
							marginLeft: "10px",
							cursor: "pointer",
							padding: "5px 5px",
							border: "1px solid #f29ca4",
							borderRadius: "50px",
						}}
						aria-label='add an alarm'
						onClick={(e) => {
							setTextPage([...textPage, ""]);
							setCurrentPage(textPage.length);
						}}
					>
						<Add color='#f29ca4' />
					</IconButton>
				</div>
			</div>
			<div className='colWrapperWriter col2'>
				<div className='buttonsWriter'>
					<button
						id='buttonHomeWriter'
						type='button'
						name='home'
						onClick={handleRedirecting}
					>
						Home
					</button>
					<button
						id='buttonSpaceWriter'
						type='button'
						name='space'
						onClick={handleRedirecting}
					>
						Il tuo Spazio
					</button>
				</div>
				<div className='buttonsWriter'>
					<Button
						id='buttonWriterDraft'
						onClick={handleSaveForm}
						style={{
							textTransform: "none",
							color: "#000",
						}}
					>
						Salva
					</Button>

					<Button
						name='pubblica'
						id='buttonWriterDraft'
						disabled={!enabledButton}
						style={{
							textTransform: "none",
							color: !enabledButton ? "#ccc" : "#000",
						}}
						onClick={publishChapter}
					>
						Pubblica
					</Button>

					{!enabledButton && (
						<span
							style={{
								textAlign: "center",
								color: "#ccc",
								textDecoration: "underline",
							}}
						>
							Prima devi salvare
						</span>
					)}
				</div>
			</div>

			{isModalOpen === "open" ? (
				<Modal
					style={{ top: "0", zIndex: "1" }}
					title='Attenzione!'
					body='I dati relativi al capitolo non sono stati ancora salvati. Per non perderli, assicurati di salvarli!'
					buttonText='Indietro'
					buttonTextOk='Conferma'
					onClickOk={() => {
						window.location.replace("/write");
					}}
					onClose={() => {
						setIsModalOpen("");
					}}
					onClick={() => {
						window.location.replace("/write");
					}}
				/>
			) : (
				""
			)}

			{isConfirmSaved?.modal && (
				<Modal
					style={{ top: "0", zIndex: "1" }}
					title='Congratulazioni!'
					body={isConfirmSaved?.text}
					buttonTextOk='Chiudi'
					onClickOk={() => {
						setIsConfirmSaved(false);
					}}
				/>
			)}
		</form>
	);

	return writer;
};

export default Writer;
