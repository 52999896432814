import React, { useEffect } from "react";
import { useState } from "react";
import Header from "./Header";
import Loading from "./Loading";
import Modal from "./Modal";
import "../css/Wallet.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BASE_URL from "./Constants";
import { Grid } from "@mui/material";
import "react-multi-carousel/lib/styles.css";
import api from "../api";

const Read = () => {
	const isLoggedIn = localStorage.getItem("isLoggedIn");

	const isLoadingGlobal = useSelector((state) => state.loadingState.value);
	const navigate = useNavigate();
	const [book, setBook] = useState([]);
	const [category, setCategory] = useState([]);
	const [singleCategory, setSingleCategory] = useState();
	const [categoryImage, setCategoryImage] = useState();

	const [selectedBook, setSelectedBook] = useState();
	useEffect(() => {
		api.get(`${BASE_URL}/book/getAll`).then((res) => {
			setSelectedBook(res.data?.data[0]);
			setBook(res.data?.data);
		});
		api.get(`${BASE_URL}/category/getAllReding`).then((res) => {
			setCategory(res.data?.data);
			console.log(res.data.data);
			setSingleCategory(res.data?.data[0]?.id);
			console.log(res.data?.data[0]?.img);
		});
	}, []);

	useEffect(() => {
		category
			?.filter((el) => el?.id === singleCategory)
			.map((el) => setCategoryImage(el?.image));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [singleCategory]);

	const [isLoadingLocal, setIsLoadingLocal] = useState(isLoadingGlobal);
	setTimeout(() => {
		setIsLoadingLocal(false);
	}, 1900);

	useEffect(() => {
		/* book
			?.filter((el) => el?.cod_category === singleCategory)
			.map((el) => setSelectedBook(el)); */

		if (book?.length > 0)
			setSelectedBook(
				book?.filter((el) => el?.cod_category === singleCategory)[0]
			);

		if (singleCategory === 1) setSelectedBook(book[0]);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [singleCategory]);

	let wallet = (
		<div>
			<Header
				title='SCEGLI UN INOOK'
				to='/home'
			/>
			<Grid
				container
				style={{
					padding: 30,
					backgroundImage: `url(${categoryImage})`,
					backgroundAttachment: "scroll",
					backgroundSize: "cover",
				}}
			>
				<Grid
					item
					xs={3}
					style={{
						height: "80vh",
						backgroundColor: "#F5F5F5",
						borderRadius: 50,
						padding: 50,
						overflow: "scroll",
					}}
				>
					<span style={{ fontSize: 25, color: "#000", fontWeight: "bold" }}>
						{selectedBook?.title}
					</span>
					<br />
					<span
						style={{
							fontSize: 25,
							color: "#000",
							fontWeight: "lighter",
							fontStyle: "italic",
						}}
					>
						{selectedBook?.name + " " + selectedBook?.surname}
					</span>
					<br />
					<div
						style={{
							maxWidth: "100%",
							overflow: "hidden", // Aggiunto per evitare overflow
							textOverflow: "ellipsis", // Aggiunto per indicare overflow
							whiteSpace: "pre-wrap", // Aggiunto per andare a capo
							wordWrap: "break-word", // Aggiunto per spezzare le parole lunghe
						}}
					>
						<span
							style={{
								fontSize: 25,
								color: "#000",
								fontWeight: "lighter",
								display: "block",
							}}
						>
							{selectedBook?.description}
						</span>
					</div>
				</Grid>
				<Grid
					item
					xs={9}
					display={"flex"}
					flexDirection={"column"}
					width={"80vw"}
				>
					<Grid
						xs={1}
						style={{ width: "100%", padding: 50 }}
					>
						<div style={{ display: "flex", flexDirection: "row" }}>
							{category?.length > 0 &&
								// render only if category is not empty
								category?.map((category) => {
									return (
										<h5
											style={{
												padding: "20px 20px",
												backgroundColor:
													singleCategory === category?.id ? "#f19ca4" : "#fff",
												borderRadius: 50,
												fontSize: 15,
												width: "fit-content",
												minWidth: 150,
												height: 50,
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												marginRight: 30,
												opacity: 1,
												cursor: "pointer",
												color:
													singleCategory === category?.id ? "#fff" : "#000",
											}}
											onClick={() => setSingleCategory(category?.id)}
										>
											{category.title}
										</h5>
									);
								})}
						</div>
					</Grid>

					<Grid
						xs={4}
						style={{ width: "100%", padding: "0px 50px" }}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								flexWrap: "nowrap",
								overflowX: "scroll",
								overflowY: "hidden",

								width: "70vw",
								height: 200,

								alignItems: "center",
								alignContent: "center",
							}}
						>
							{book?.length > 0 &&
								book
									?.filter((el) => {
										if (singleCategory !== 1)
											return el?.cod_category === singleCategory;
										else return true;
									})
									?.map((book) => {
										return (
											<div
												style={{
													backgroundColor: "#fff",
													fontSize: 15,
													width: "67vw",
													marginRight: 20,
													minWidth: selectedBook?.id === book?.id ? 160 : 100,
													maxWidth: selectedBook?.id === book?.id ? 160 : 100,
													height: selectedBook?.id === book?.id ? 180 : 120,
													display: "flex",
													justifyContent: "center",
													border:
														selectedBook?.id === book?.id
															? "solid 8px #f19ca4"
															: "solid 5px #f19ca4",
													alignItems: "center",
													opacity: 1,
													cursor: "pointer",
													color:
														selectedBook?.id === book?.id ? "#000" : "#000",
												}}
												onClick={() =>
													selectedBook === book
														? navigate(`/readBook/${book.id}`)
														: setSelectedBook(book)
												}
											>
												<img
													src={book.imgPath}
													style={{
														width: "100%",
														height: "100%",
														objectFit: "cover",
														objectPosition: "center",
													}}
													alt='book'
												/>
											</div>
										);
									})}
						</div>
					</Grid>

					<Grid
						xs={4}
						style={{ width: "80vw", padding: 50 }}
					>
						<div
							style={{
								padding: "0",
								width: "67vw",
								height: "300px",
								backgroundColor: "rgba(255, 255, 255, 0.5)",
								borderRadius: 50,
								display: "flex",

								paddingLeft: 50,
								paddingTop: 50,
							}}
						>
							<div
								dangerouslySetInnerHTML={{
									__html: category?.filter(
										(el) => el?.id === singleCategory
									)?.[0]?.description,
								}}
								style={{ fontSize: 21, color: "#000" }}
							/>
						</div>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
	return isLoadingLocal ? <Loading /> : wallet;
};

export default Read;
