import { Button } from "@mui/material";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const AlertModal = ({
	handleClickDelete,
	title = "",
	body = "",
	cancelButton = "",
	okButton = "",
	type = "confirm",
}) => {
	return confirmAlert({
		customUI: ({ onClose }) => {
			return (
				<div
					style={{
						backgroundColor: "rgb(255, 255, 255)",
						width: "100%",
						minWidth: "400px",
						border: "10px solid #f29da5",
						padding: "40px",
						borderRadius: "50px",
					}}
				>
					<h1>{title}</h1>
					<p>{body}</p>

					{type === "confirm" ? (
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								borderRadius: 50,
								border: "px solid #000",
							}}
						>
							<Button
								onClick={onClose}
								sx={{
									color: "#f29da5",
									backgroundColor: "#fff",
									border: "1px solid #f29da5",
								}}
							>
								{cancelButton}
							</Button>
							<Button
								onClick={() => {
									handleClickDelete();
									onClose();
								}}
								sx={{
									color: "#fff",
									backgroundColor: "#f29da5",
									border: "1px solid #f29da5",
									":hover": {
										color: "#fff",
										backgroundColor: "#f29da5",
									},
								}}
							>
								{okButton}
							</Button>
						</div>
					) : (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignContent: "center",
								alignItems: "center",
								width: "100%",
								borderRadius: 50,
								border: "px solid #000",
							}}
						>
							<Button
								onClick={onClose}
								sx={{
									color: "#f29da5",
									backgroundColor: "#fff",
									border: "1px solid #f29da5",
								}}
							>
								{cancelButton}
							</Button>
						</div>
					)}
				</div>
			);
		},
	});
};

export default AlertModal;
