import React from "react";
import "../css/RegDenied.css"
import {useNavigate} from 'react-router-dom';

function RegDenied(){

    const navigate = useNavigate();

    return(
        <div className="mainBorder">
            
            <p id="deniedParagraph">
                <strong>Oooooops...</strong><br></br>
                <p id="deniedParagraph_1" >
                    <strong>
                        Durante il processo di registrazione<br></br>qualcosa è andato storto<br></br>
                    </strong>
                </p>

            </p>

            <p id="deniedParagraph_2">
                Ti invitiamo a ritentare il processo di registrazione<br></br>
                per poter accedere ai contenuti inook.                
            </p>
            
            <div className="deniedRedirecting">
                <button onClick={() => {navigate("/")}} className="deniedButton">
                    Vai al Log-in
                </button>
                <div className="deniedButtonShadow"></div>
                
            </div>
        </div>
)}

export default RegDenied;