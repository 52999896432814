import React from "react";
import inookgif from "../img/Login/Loader.gif";
import "../css/Loader.css";

let Loading = () => {
	return (
		<div className='loaderBackground'>
			<img
				className='Loader'
				src={inookgif}
				start='fileopen'
				alt='loader'
			/>
		</div>
	);
};

export default Loading;
