import React from "react";
import { useState } from "react";
import Header from "./Header";
import SignUpMenu from "./SignUpMenu";
import Modal from "./Modal";
import Loading from "./Loading";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changeLoadingState } from "../features/loadingState";
import { changeModalState } from "../features/modalState";

function Register() {
	const isLoadingGlobal = useSelector((state) => state.loadingState.value);
	const isModalGlobal = useSelector((state) => state.modalState.value);
	const dispatchLoadingState = useDispatch();
	const dispatchModalState = useDispatch();
	const navigate = useNavigate();

	const [isLoadingLocal, setIsLoadingLocal] = useState(isLoadingGlobal);
	setTimeout(() => {
		setIsLoadingLocal(false);
	}, 1900);

	function handleClick(message) {
		if (message === "success") {
			dispatchModalState(changeModalState(""));
			dispatchLoadingState(changeLoadingState(true));
			navigate("/");
		} else if (message === "error") {
			dispatchModalState(changeModalState(""));
		} else if (message === "fail") {
			dispatchModalState(changeModalState(""));
		}
	}

	const RegisterMenu = (
		<div>
			<Header
				title='SIGN-IN'
				to='/'
			/>
			<SignUpMenu />

			{isModalGlobal === "privacy" ? (
				<Modal
					style={{ top: "0", zIndex: "1" }}
					title='Attenzione!'
					body='Il processo di registrazione non può proseguire senza aver accettato la privacy policy'
					buttonText='Riprova'
					onClose={() => handleClick("fail")}
					onClick={() => handleClick("fail")}
				/>
			) : (
				""
			)}

			{isModalGlobal === "success" ? (
				<Modal
					style={{ top: "0", zIndex: "1" }}
					title='Fantastico!'
					body='In pochi minuti riceverai una mail con un codice necessario per completare la registrazione. Intanto verrai reindirizzato alla pagina di login.'
					buttonText='Continua...'
					onClose={() => handleClick("success")}
					onClick={() => handleClick("success")}
				/>
			) : (
				""
			)}

			{isModalGlobal === "error" ? (
				<Modal
					style={{ top: "0", zIndex: "1" }}
					title='Ops..'
					body='Il processo di registrazione non è andato a buon fine. Lo username inserito potrebbe essere già occupato oppure la mail di registrazione è già stata utilizzata.'
					buttonText='Riprova'
					onClose={() => handleClick("error")}
					onClick={() => handleClick("error")}
				/>
			) : (
				""
			)}

			{isModalGlobal === "fail" ? (
				<Modal
					style={{ top: "0", zIndex: "1" }}
					title='Attenzione!'
					body='I campi password e conferma password risultano diversi, prova a reinserire entrambi i valori correttamente.'
					buttonText='Riprova'
					onClose={() => handleClick("fail")}
					onClick={() => handleClick("fail")}
				/>
			) : (
				""
			)}
		</div>
	);

	return <div>{isLoadingLocal ? <Loading /> : RegisterMenu}</div>;
}

export default Register;
