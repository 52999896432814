import React, { useEffect } from "react";
import { useState } from "react";
import Header from "./Header";
import NavMenu from "./NavMenu";
import Loading from "./Loading";
import Modal from "./Modal";
import "../css/Wallet.css";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changeLoadingState } from "../features/loadingState";
import BASE_URL from "./Constants";
import { Tab, Tabs, Box } from "@mui/material";
import CustomTabPanel, { a11yProps } from "./CustomTabPanels";
import api from "../api";

const Wallet = () => {
	// const localUserToken = localStorage.getItem("token");
	const isLoggedIn = localStorage.getItem("isLoggedIn");

	const isLoadingGlobal = useSelector((state) => state.loadingState.value);
	const dispatchLoadingState = useDispatch();
	const navigate = useNavigate();

	const [isLoadingLocal, setIsLoadingLocal] = useState(isLoadingGlobal);
	setTimeout(() => {
		setIsLoadingLocal(false);
	}, 1900);

	let handleClickRedirecting = () => {
		dispatchLoadingState(changeLoadingState(true));
	};

	const parseData = (data) => {
		const date = new Date(data);
		const day = date.getDate();
		const month = date.getMonth() + 1;
		const year = date.getFullYear();
		const hour = date.getHours();
		const minutes = date.getMinutes();
		const stringDate = `${("0" + day).slice(
			-2
		)}/${month}/${year} ${hour}:${minutes}`;
		return stringDate;
	};
	const [donations, setDonations] = useState([]);
	const [coin, setCoin] = useState(0);
	const id = localStorage.getItem("id");

	useEffect(() => {
		grabDonations();
	}, []);

	function IsNotUneven(numero) {
		if (isNaN(numero) === false) {
			return numero % 2 === 1 ? true : false;
		} else {
			return null;
		}
	}

	let grabDonations = async () => {
		try {
			let res = await api.get(`${BASE_URL}/donation/returnTransactionUser`);
			setDonations(res.data.data);
			console.log(res);
			setCoin(res.data?.amount);
			//setDonations(res.data.data);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		console.log(donations);
	}, [donations]);

	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	let DonationSummary = () => {
		const lengthSender = donations?.filter(
			(el) => el?.SenderID.toString() === id
		);
		const lengthReceiver = donations?.filter(
			(el) => el?.ReceiverID.toString() === id
		);

		let max = Math.max(lengthSender?.length, lengthReceiver?.length);
		const array = [];
		for (let i = 0; i < max; i++) {
			array.push(i);
		}

		return (
			<Box sx={{ width: "100%" }}>
				<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
					<Tabs
						sx={{
							".Mui-selected": {
								color: `#f29da5`,
								fontWeight: "bold",
							},
						}}
						TabIndicatorProps={{
							sx: { color: "#f29da5" },
							style: {
								backgroundColor: "#f29da5",
								color: "#f29da5",
							},
						}}
						value={value}
						onChange={handleChange}
						aria-label='basic tabs example'
					>
						<Tab
							label={
								<span style={{ color: value === 0 ? "#f29da5" : "#000" }}>
									Hai donato
								</span>
							}
							{...a11yProps(0)}
						/>
						<Tab
							label={
								<span style={{ color: value === 1 ? "#f29da5" : "#000" }}>
									Hai ricevuto
								</span>
							}
							{...a11yProps(1)}
						/>
					</Tabs>
				</Box>
				<CustomTabPanel
					value={value}
					index={0}
				>
					{lengthSender?.length === 0 ? (
						<span>Non hai fatto nessuna donazione</span>
					) : (
						<table style={{ width: "100%" }}>
							<thead>
								<tr>
									<th>Data</th>
									<th>Importo</th>
									<th>Libro</th>
								</tr>
							</thead>
							<tbody>
								{array.map((el) => {
									return (
										<tr
											style={{
												backgroundColor: IsNotUneven(el) ? "#e8e8e8" : "#fff",
												border: "none",
											}}
										>
											<td style={{ border: "none" }}>
												{parseData(lengthSender[el]?.TransactionTime)}
											</td>
											<td style={{ border: "none" }}>
												{lengthSender[el]?.amount}
											</td>
											<td style={{ border: "none" }}>
												{lengthSender[el]?.BookTitle}
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					)}
				</CustomTabPanel>
				<CustomTabPanel
					value={value}
					index={1}
				>
					{lengthReceiver?.length === 0 ? (
						<span>Non hai ricevuto nessuna donazione</span>
					) : (
						<table style={{ width: "100%" }}>
							<thead>
								<tr>
									<th>Data</th>
									<th>Importo</th>
									<th>Libro</th>
								</tr>
							</thead>
							<tbody>
								{array.map((el) => {
									return (
										<tr
											style={{
												backgroundColor: IsNotUneven(el) ? "#e8e8e8" : "#fff",
											}}
										>
											<td style={{ border: "none" }}>
												{parseData(lengthReceiver[el]?.TransactionTime)}
											</td>
											<td style={{ border: "none" }}>
												{lengthReceiver[el]?.amount}
											</td>
											<td style={{ border: "none" }}>
												{lengthReceiver[el]?.BookTitle}
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					)}
				</CustomTabPanel>
			</Box>
		);
	};

	let wallet = (
		<div>
			<Header
				title='IL TUO SPAZIO'
				to='/home'
			/>
			<div className='baseWrapper'>
				<NavMenu onClick={() => handleClickRedirecting()} />
				<div
					className='choicesWrapperWallet'
					style={{ width: "70%" }}
				>
					<div
						className='coinCounterWrapper'
						style={{
							display: "flex",
							justifyContent: "center",
							width: "20%",
							alignContent: "center",
							alignItems: "center",
						}}
					>
						<div className='WalletCentered'>HAI</div>
						<div
							className='WalletCentered'
							style={{
								padding: 50,
								height: 150,
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								width: 150,
								color: "white",
								backgroundColor: "#f29da5",
								borderRadius: "150px",
								fontSize: 60,
							}}
						>
							{coin}
						</div>
						<div className='WalletCentered'>GETTONI</div>
					</div>
					<div style={{ width: "60%" }}>
						<DonationSummary />
					</div>
				</div>
			</div>
		</div>
	);

	return wallet;
};

export default Wallet;
