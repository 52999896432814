import React from "react";
import "../css/Header.css";
import pencil from "../img/Header/pencil.png";
import share from "../img/Header/share.png";

import off_button from "../img/Header/off_button.png";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeLoadingState } from "../features/loadingState";

function Header(props) {
	const dispatchLoadingState = useDispatch();

	let logout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("isLoggedIn");
		localStorage.removeItem("id");
		localStorage.removeItem("email");
		localStorage.removeItem("username");
		localStorage.removeItem("name");
		localStorage.removeItem("surname");
		localStorage.removeItem("date");
		localStorage.removeItem("imguser");
	};

	let handleHomeRedirecting = () => {
		dispatchLoadingState(changeLoadingState(true));
	};

	return (
		<div className='header'>
			<div className='pencil_title_box'>
				<Link
					className='pencilBox'
					to={"/home"}
					onClick={() => handleHomeRedirecting}
				>
					<img
						className='pencil'
						src={pencil}
						alt='pencil'
					/>
				</Link>
				<div className='sign-in'>{props.title}</div>
			</div>

			{props?.action && (
				<div className='logout_box'>
					<Link
						to={"/"}
						onClick={props.onClick}
					>
						<img
							className='share_button'
							src={share}
							alt='off button'
						/>
					</Link>
				</div>
			)}
			<div className='logout_box'>
				<Link
					to={"/"}
					onClick={() => logout()}
				>
					<img
						className='off_button'
						src={off_button}
						alt='off button'
					/>
				</Link>
			</div>
		</div>
	);
}

export default Header;
