import React from "react";
import { useState } from "react";
import Header from "./Header";
import Loading from "./Loading";
import "../css/Privacy.css";
import { useSelector, useDispatch } from "react-redux";
import {useNavigate} from 'react-router-dom';
import { changeLoadingState } from "../features/loadingState";

// DEFINIRE LA PRIVACY POLICY



function Privacy() {

    const isLoadingGlobal = useSelector((state) => state.loadingState.value);
    const dispatchLoadingState = useDispatch();
    const navigate = useNavigate();
    
    const [isLoadingLocal, setIsLoadingLocal] = useState(isLoadingGlobal);
    setTimeout(() => {
        setIsLoadingLocal(false)
    }, 1900);

    function handleClick(){
        dispatchLoadingState(changeLoadingState(true));
        navigate("/register")
    }

    let privacy = (
        <div className="privacyContainer">
            <Header title="Privacy Policy" to="/"/>
            <div className="privacyWrapper">
                <div className="privacyBody">
                    In anim nostrud velit pariatur est cillum nulla in. Sint deserunt qui sit eu ex adipisicing elit est voluptate laborum ad fugiat. Adipisicing velit officia adipisicing ad commodo aliqua anim velit cillum. Velit proident proident ad Lorem fugiat esse aute consequat. Proident consequat sint velit nulla velit laboris nostrud. Irure ullamco cupidatat dolor deserunt do velit est. Incididunt commodo aliquip laboris in nostrud excepteur ut eiusmod.Reprehenderit exercitation voluptate aliqua consectetur sit veniam ut consequat ullamco anim aliqua eiusmod exercitation. Anim aliquip exercitation excepteur duis ea. Ex minim labore do nulla occaecat adipisicing fugiat ipsum irure velit voluptate duis ut. Ut veniam aute laboris laborum nostrud aliquip ipsum est.Dolor ex eiusmod nulla in Lorem aliqua eu eu incididunt voluptate eu. Magna do id deserunt amet eiusmod mollit do veniam sunt. Reprehenderit et est cillum nisi eiusmod esse sint voluptate. Ipsum sint sunt id mollit sunt veniam eiusmod sint exercitation velit sunt eiusmod eiusmod. Id elit labore minim dolor enim enim.In laboris magna excepteur consequat aliqua voluptate. Anim cillum magna culpa aliquip veniam velit dolore commodo nulla ut aliqua do aute. Irure laborum excepteur enim sint incididunt fugiat Lorem.Consequat occaecat laboris deserunt quis dolor amet ex eu. Eu dolor cillum nulla tempor ex qui aute non minim do deserunt. Tempor consectetur cillum exercitation aliquip eu. Laborum sunt dolore dolore veniam veniam deserunt cillum ut minim quis fugiat pariatur qui. Cupidatat ea eiusmod aliqua consectetur officia nostrud et. Lorem elit aliquip pariatur consectetur. Deserunt non aliqua fugiat cupidatat deserunt enim culpa dolore.
                </div>
                <div className="privacyButtonWrapper">
                    <button className="privacyButton" onClick={() => handleClick()}>Torna al Sign-in</button>
                </div>
            </div>
        </div>
    )


    return (
        <div>
            {isLoadingLocal ? <Loading /> : privacy}
        </div>
    );
}

export default Privacy;