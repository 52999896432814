import { createSlice } from "@reduxjs/toolkit";

const initialState = true;

export const loadingSlice = createSlice({
    name: "loadingState",
    initialState: { value: initialState},
    reducers: {
        changeLoadingState: (state, action) => {
            state.value = action.payload;
        }
    }
})

export const { changeLoadingState } = loadingSlice.actions;

export default loadingSlice.reducer;