import { createSlice } from "@reduxjs/toolkit";

const initialState = "";

export const emailSlice = createSlice({
    name: "email",
    initialState: { value: initialState},
    reducers: {
        addUserEmail: (state, action) => {
            state.value = action.payload;
        }
    }
})

export const { addUserEmail } = emailSlice.actions;

export default emailSlice.reducer;