import React, { useState } from "react";
import "../css/RegConfirmed.css";
import congrats from "../img/RegConfirmation/congratulazioni.png";
import { useNavigate } from "react-router-dom";
import Loading from "./Loading";
import { useSelector, useDispatch } from "react-redux";
import { changeLoadingState } from "../features/loadingState";

function RegConfirmed() {
	const navigate = useNavigate();
	const isLoadingGlobal = useSelector((state) => state.loadingState.value);
	const dispatchLoadingState = useDispatch();

	const [isLoadingLocal, setIsLoadingLocal] = useState(isLoadingGlobal);
	setTimeout(() => {
		setIsLoadingLocal(false);
	}, 1900);

	function handleClick() {
		dispatchLoadingState(changeLoadingState(true));
		navigate("/");
	}

	let registationConfirmedComponent = (
		<div className='mainBorder'>
			<img
				className='congratsImage'
				src={congrats}
				alt='congrats'
			/>
			<p id='congratsParagraph'>
				<strong>
					Da questo momento anche tu sei un{" "}
					<span className='congratsInooker'>inooker</span>.<br></br>
				</strong>
				<span id='congratsParagraph_2'>
					Adesso puoi pubblicare e leggere gli inook!
				</span>
			</p>

			<div className='congratsRedirecting'>
				<button
					onClick={() => handleClick()}
					className='congratsButton'
				>
					Vai al Log-in
				</button>
				<p className='congratsWishes'>
					<strong>
						Buon inizio dal team di <span className='congratsInook'>inook</span>
						!
					</strong>
				</p>
			</div>
		</div>
	);

	return (
		<div>{isLoadingLocal ? <Loading /> : registationConfirmedComponent}</div>
	);
}

export default RegConfirmed;
